import ChevronDownIcon from '../Icon/ChevronDownIcon'

interface FormSelectOption {
  label?: string
  value: string
  disabled?: boolean
}

function FormSelect({
  id,
  options,
  value,
  onChange,
  placeholder = '',
}: {
  id: string
  options: FormSelectOption[]
  value: string
  onChange: (value: string) => void
  placeholder?: string
}): JSX.Element {
  return (
    <div className="relative w-full leading-none border border-gray-200 rounded-md h-9 focus-within:border-blue-600 focus-within:ring-2 focus-within:ring-blue-200 group">
      <select
        id={id}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={[
          'appearance-none pl-2.5 pr-10 h-full text-sm focus:outline-none rounded-md w-full',
          !placeholder && 'text-gray-800',
          placeholder && !value && 'text-gray-400',
          placeholder && value && 'text-gray-800',
        ]
          .filter(Boolean)
          .join(' ')}
      >
        {placeholder && (
          <option disabled value="">
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} disabled={option.disabled} value={option.value}>
            {option.label || option.value}
          </option>
        ))}
      </select>
      <span className="absolute top-0 bottom-0 right-0 flex items-center justify-center h-full pointer-events-none w-9">
        <ChevronDownIcon className="w-6 h-6 text-gray-400 group-hover:text-gray-500" />
      </span>
    </div>
  )
}

export default FormSelect
