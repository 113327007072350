/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import { SeaportFieldsFragmentDoc } from './seaports-gql.generated'
import { VesselScheduleFieldsFragmentDoc } from './vessel-schedules-gql.generated'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type LoadLandingVesselsOverviewQueryVariables = Types.Exact<{ [key: string]: never }>

export type LoadLandingVesselsOverviewQuery = {
  landingVessels: {
    vessels: Array<{
      id: string
      imo: number
      charterName: string
      unknownSeamen: string
      uniqueOnsigners: boolean
      uniqueOffsigners: boolean
      errorLevel: string
      hasCrewChangesAtCanals: boolean
      hasNoSchedules: boolean
      hasManualSchedules: boolean
      hasNoCrewChanges: boolean
      hasUnassignedScheduleIds: boolean
      hasUnassignedScheduleIdsInFuture: boolean
      vesselScheduleSource: Types.VesselScheduleSource
      tenantId: string
    }>
    dataSyncLog: {
      createdAt: string
      user: { firstName: string | null; lastName: string | null } | null
    } | null
    erroneousVessels: Array<{ vesselId: string; charterName: string; createdAt: string }>
  }
}

export type LoadLandingVesselDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input']
}>

export type LoadLandingVesselDetailQuery = {
  landingVessel: {
    vessel: {
      id: string
      imo: number
      charterName: string
      vesselScheduleSource: Types.VesselScheduleSource
    }
    schedules: Array<{
      id: string
      ETA: string
      ETB: string
      ETD: string
      sourceId: string | null
      source: Types.VesselScheduleSource
      portName: string | null
      portTerminal: string | null
      portCountry: string | null
      updatedAt: string
      crewChangeLinkage: { bucketId: string | null; isLinked: boolean } | null
      updatedBy: { firstName: string | null; lastName: string | null } | null
      seaport: {
        id: string
        name: string
        nameAlpha2: string | null
        alternateNames: Array<string>
        countryAlpha2: string
        locode: string
        latitude: number
        longitude: number
        timezoneOlson: string | null
      }
    }>
    viewCrewChanges: Array<{
      id: string
      scheduleId: string
      seaportName: string
      startOn: string | null
      stopOn: string | null
      hanseaticNearestAirportIata: string
      crewHash: string
    }>
    viewSeamanCrewChanges: Array<{
      id: string
      crewHash: string
      sourceId: string | null
      firstName: string | null
      lastName: string | null
      type: Types.LandingCrewChangeSeamanType
      isUnk: boolean
      passportNumber: string | null
      passportNationalityIso: string | null
      passportIssueAt: string | null
      passportExpiryAt: string | null
      passportIssuePlace: string | null
      homeAirportIata: string | null
      birthday: string | null
    }>
  }
}

export type LandingInitiateDataSyncActionMutationVariables = Types.Exact<{
  action: Types.DataSyncAction
}>

export type LandingInitiateDataSyncActionMutation = { landingAction: void | null }

export type WipeLandingTablesMutationVariables = Types.Exact<{ [key: string]: never }>

export type WipeLandingTablesMutation = { wipeLandingTables: void | null }

export type AutoUpdatePnrltDsMutationVariables = Types.Exact<{ [key: string]: never }>

export type AutoUpdatePnrltDsMutation = { autoUpdatePNRLTDs: void | null }

export type DeleteLandingVesselMutationVariables = Types.Exact<{
  imo: Types.Scalars['Int']['input']
}>

export type DeleteLandingVesselMutation = { deleteLandingVessel: void | null }

export type DeleteErroneousCrewChangeMutationVariables = Types.Exact<{
  imo: Types.Scalars['Int']['input']
  crewHash: Types.Scalars['String']['input']
}>

export type DeleteErroneousCrewChangeMutation = { deleteErroneousCrewChanges: void | null }

export type ResetSyncJobMutationVariables = Types.Exact<{ [key: string]: never }>

export type ResetSyncJobMutation = { resetSyncJob: void | null }

export type AddAndLinkManualScheduleMutationVariables = Types.Exact<{
  params: Types.AddManualVesselScheduleInput
}>

export type AddAndLinkManualScheduleMutation = {
  addManualVesselSchedule: {
    id: string
    ETA: string
    ETB: string
    ETD: string
    updatedAt: string
    createdAt: string
    bunkering: boolean
    cancelled: boolean
    COSP: string | null
    discharging: boolean
    EOSP: string | null
    loading: boolean
    portCountry: string | null
    portName: string | null
    portTerminal: string | null
    sourceId: string | null
    source: Types.VesselScheduleSource
    TOA: string | null
    TOD: string | null
    validFrom: string | null
    validUntil: string | null
    isPlannedSeaport: boolean
    plannedSeaportId: string | null
    voyageNumber: string | null
  }
}

export type LinkScheduleToCrewChangeMutationVariables = Types.Exact<{
  vesselScheduleSourceId: Types.Scalars['String']['input']
  crewHash: Types.Scalars['String']['input']
}>

export type LinkScheduleToCrewChangeMutation = { linkScheduleToCrewChange: void | null }

export type CreateManualSchedulesFromCsvMutationVariables = Types.Exact<{
  file: Types.Scalars['Upload']['input']
}>

export type CreateManualSchedulesFromCsvMutation = {
  createManualSchedulesFromCsv: Array<{
    vessel: string
    port: string
    eta: string | null
    etd: string | null
    imo: string
    errorMessage: string | null
  }>
}

export type LoadUnreviewedPnRsQueryVariables = Types.Exact<{ [key: string]: never }>

export type LoadUnreviewedPnRsQuery = {
  unreviewedPNRs: Array<{
    flightBooking: {
      id: string
      pnr: string | null
      lastTicketingTimeSource: Types.LastTicketingTimeSource
      bucketId: string | null
      seaman: { id: string; firstName: string; lastName: string }
    }
    vesselSchedule: {
      id: string
      portName: string | null
      ETA: string
      ETB: string
      ETD: string
      vessel: { id: string; charterName: string }
      seaport: { id: string; timezoneOlson: string | null }
    }
  }>
}

export type LoadReviewedPnRsQueryVariables = Types.Exact<{ [key: string]: never }>

export type LoadReviewedPnRsQuery = {
  recentlyViewedPNRs: Array<{
    flightBooking: {
      id: string
      pnr: string | null
      lastTicketingTimeSource: Types.LastTicketingTimeSource
      bucketId: string | null
      seaman: { id: string; firstName: string; lastName: string }
    }
    vesselSchedule: {
      id: string
      portName: string | null
      ETA: string
      ETB: string
      ETD: string
      vessel: { id: string; charterName: string }
      seaport: { id: string; timezoneOlson: string | null }
    }
  }>
}

export type LandingPnrSummaryFieldsFragment = {
  flightBooking: {
    id: string
    pnr: string | null
    lastTicketingTimeSource: Types.LastTicketingTimeSource
    bucketId: string | null
    seaman: { id: string; firstName: string; lastName: string }
  }
  vesselSchedule: {
    id: string
    portName: string | null
    ETA: string
    ETB: string
    ETD: string
    vessel: { id: string; charterName: string }
    seaport: { id: string; timezoneOlson: string | null }
  }
}

export type LoadPnrInfoQueryVariables = Types.Exact<{
  params: Types.PnrInfoParams
}>

export type LoadPnrInfoQuery = {
  pnrInfo: {
    marketingCarriers: Array<string>
    originalLTD: string | null
    rawPnr: string
    officeId: string | null
    fares: Array<{
      amount: number | null
      tax: number | null
      currency: Types.Currency
      lastTicketingDate: string | null
      baggageDetails: {
        freeBagAllowance: number | null
        freeBagAllowanceType: Types.FreeBagAllowanceType | null
      } | null
    }> | null
    possibleLtds: Array<{
      source: string
      originalLtd: string
      timezone: string
      offset: string
      score: number | null
      utcLtd: string
    }>
  }
}

export type AddPnrSelectionDataMutationVariables = Types.Exact<{
  pnr: Types.Scalars['String']['input']
  xml: Types.Scalars['String']['input']
  selectedLtd: Types.Scalars['DateTime']['input']
  possibleLtdSelections: Array<Types.PossiblePnrLtdSelection> | Types.PossiblePnrLtdSelection
}>

export type AddPnrSelectionDataMutation = { addPnrSelectionData: void | null }

export type UpdatePnrltdMutationVariables = Types.Exact<{
  pnr: Types.Scalars['String']['input']
  ltd: Types.Scalars['DateTime']['input']
}>

export type UpdatePnrltdMutation = { updatePNRLTD: void | null }

export type NotifyLtdNearbyMutationVariables = Types.Exact<{
  pnr: Types.Scalars['String']['input']
}>

export type NotifyLtdNearbyMutation = { notifyLTDNearby: void | null }

export type ReplaceVesselScheduleMutationVariables = Types.Exact<{
  scheduleId: Types.Scalars['UUID']['input']
  replacementScheduleId: Types.Scalars['UUID']['input']
}>

export type ReplaceVesselScheduleMutation = { replaceVesselSchedule: void | null }

export const LandingPnrSummaryFieldsFragmentDoc = gql`
  fragment LandingPNRSummaryFields on LandingPNRSummary {
    flightBooking {
      id
      pnr
      lastTicketingTimeSource
      bucketId
      seaman {
        id
        firstName
        lastName
      }
    }
    vesselSchedule {
      id
      portName
      ETA
      ETB
      ETD
      vessel {
        id
        charterName
      }
      seaport {
        id
        timezoneOlson
      }
    }
  }
`
export const LoadLandingVesselsOverviewDocument = gql`
  query LoadLandingVesselsOverview {
    landingVessels {
      vessels {
        id
        imo
        charterName
        unknownSeamen
        uniqueOnsigners
        uniqueOffsigners
        errorLevel
        hasCrewChangesAtCanals
        hasNoSchedules
        hasManualSchedules
        hasNoCrewChanges
        hasUnassignedScheduleIds
        hasUnassignedScheduleIdsInFuture
        vesselScheduleSource
        tenantId
      }
      dataSyncLog {
        user {
          firstName
          lastName
        }
        createdAt
      }
      erroneousVessels {
        vesselId
        charterName
        createdAt
      }
    }
  }
`

/**
 * __useLoadLandingVesselsOverviewQuery__
 *
 * To run a query within a React component, call `useLoadLandingVesselsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLandingVesselsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLandingVesselsOverviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadLandingVesselsOverviewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadLandingVesselsOverviewQuery,
    LoadLandingVesselsOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoadLandingVesselsOverviewQuery, LoadLandingVesselsOverviewQueryVariables>(
    LoadLandingVesselsOverviewDocument,
    options
  )
}
export function useLoadLandingVesselsOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLandingVesselsOverviewQuery,
    LoadLandingVesselsOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LoadLandingVesselsOverviewQuery,
    LoadLandingVesselsOverviewQueryVariables
  >(LoadLandingVesselsOverviewDocument, options)
}
export function useLoadLandingVesselsOverviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadLandingVesselsOverviewQuery,
        LoadLandingVesselsOverviewQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    LoadLandingVesselsOverviewQuery,
    LoadLandingVesselsOverviewQueryVariables
  >(LoadLandingVesselsOverviewDocument, options)
}
export type LoadLandingVesselsOverviewQueryHookResult = ReturnType<
  typeof useLoadLandingVesselsOverviewQuery
>
export type LoadLandingVesselsOverviewLazyQueryHookResult = ReturnType<
  typeof useLoadLandingVesselsOverviewLazyQuery
>
export type LoadLandingVesselsOverviewSuspenseQueryHookResult = ReturnType<
  typeof useLoadLandingVesselsOverviewSuspenseQuery
>
export type LoadLandingVesselsOverviewQueryResult = Apollo.QueryResult<
  LoadLandingVesselsOverviewQuery,
  LoadLandingVesselsOverviewQueryVariables
>
export const LoadLandingVesselDetailDocument = gql`
  query LoadLandingVesselDetail($id: UUID!) {
    landingVessel(id: $id) {
      vessel {
        id
        imo
        charterName
        vesselScheduleSource
      }
      schedules {
        id
        ETA
        ETB
        ETD
        sourceId
        source
        portName
        portTerminal
        portCountry
        crewChangeLinkage {
          bucketId
          isLinked
        }
        updatedBy {
          firstName
          lastName
        }
        updatedAt
        seaport {
          ...SeaportFields
        }
      }
      viewCrewChanges {
        id
        scheduleId
        seaportName
        startOn
        stopOn
        hanseaticNearestAirportIata
        crewHash
      }
      viewSeamanCrewChanges {
        id
        crewHash
        sourceId
        firstName
        lastName
        type
        isUnk
        passportNumber
        passportNationalityIso
        passportIssueAt
        passportExpiryAt
        passportIssuePlace
        homeAirportIata
        birthday
      }
    }
  }
  ${SeaportFieldsFragmentDoc}
`

/**
 * __useLoadLandingVesselDetailQuery__
 *
 * To run a query within a React component, call `useLoadLandingVesselDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLandingVesselDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLandingVesselDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadLandingVesselDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadLandingVesselDetailQuery,
    LoadLandingVesselDetailQueryVariables
  > &
    ({ variables: LoadLandingVesselDetailQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoadLandingVesselDetailQuery, LoadLandingVesselDetailQueryVariables>(
    LoadLandingVesselDetailDocument,
    options
  )
}
export function useLoadLandingVesselDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLandingVesselDetailQuery,
    LoadLandingVesselDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LoadLandingVesselDetailQuery, LoadLandingVesselDetailQueryVariables>(
    LoadLandingVesselDetailDocument,
    options
  )
}
export function useLoadLandingVesselDetailSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadLandingVesselDetailQuery,
        LoadLandingVesselDetailQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    LoadLandingVesselDetailQuery,
    LoadLandingVesselDetailQueryVariables
  >(LoadLandingVesselDetailDocument, options)
}
export type LoadLandingVesselDetailQueryHookResult = ReturnType<
  typeof useLoadLandingVesselDetailQuery
>
export type LoadLandingVesselDetailLazyQueryHookResult = ReturnType<
  typeof useLoadLandingVesselDetailLazyQuery
>
export type LoadLandingVesselDetailSuspenseQueryHookResult = ReturnType<
  typeof useLoadLandingVesselDetailSuspenseQuery
>
export type LoadLandingVesselDetailQueryResult = Apollo.QueryResult<
  LoadLandingVesselDetailQuery,
  LoadLandingVesselDetailQueryVariables
>
export const LandingInitiateDataSyncActionDocument = gql`
  mutation LandingInitiateDataSyncAction($action: DataSyncAction!) {
    landingAction(action: $action)
  }
`
export type LandingInitiateDataSyncActionMutationFn = Apollo.MutationFunction<
  LandingInitiateDataSyncActionMutation,
  LandingInitiateDataSyncActionMutationVariables
>

/**
 * __useLandingInitiateDataSyncActionMutation__
 *
 * To run a mutation, you first call `useLandingInitiateDataSyncActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLandingInitiateDataSyncActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [landingInitiateDataSyncActionMutation, { data, loading, error }] = useLandingInitiateDataSyncActionMutation({
 *   variables: {
 *      action: // value for 'action'
 *   },
 * });
 */
export function useLandingInitiateDataSyncActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LandingInitiateDataSyncActionMutation,
    LandingInitiateDataSyncActionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LandingInitiateDataSyncActionMutation,
    LandingInitiateDataSyncActionMutationVariables
  >(LandingInitiateDataSyncActionDocument, options)
}
export type LandingInitiateDataSyncActionMutationHookResult = ReturnType<
  typeof useLandingInitiateDataSyncActionMutation
>
export type LandingInitiateDataSyncActionMutationResult =
  Apollo.MutationResult<LandingInitiateDataSyncActionMutation>
export type LandingInitiateDataSyncActionMutationOptions = Apollo.BaseMutationOptions<
  LandingInitiateDataSyncActionMutation,
  LandingInitiateDataSyncActionMutationVariables
>
export const WipeLandingTablesDocument = gql`
  mutation WipeLandingTables {
    wipeLandingTables
  }
`
export type WipeLandingTablesMutationFn = Apollo.MutationFunction<
  WipeLandingTablesMutation,
  WipeLandingTablesMutationVariables
>

/**
 * __useWipeLandingTablesMutation__
 *
 * To run a mutation, you first call `useWipeLandingTablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWipeLandingTablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wipeLandingTablesMutation, { data, loading, error }] = useWipeLandingTablesMutation({
 *   variables: {
 *   },
 * });
 */
export function useWipeLandingTablesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WipeLandingTablesMutation,
    WipeLandingTablesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<WipeLandingTablesMutation, WipeLandingTablesMutationVariables>(
    WipeLandingTablesDocument,
    options
  )
}
export type WipeLandingTablesMutationHookResult = ReturnType<typeof useWipeLandingTablesMutation>
export type WipeLandingTablesMutationResult = Apollo.MutationResult<WipeLandingTablesMutation>
export type WipeLandingTablesMutationOptions = Apollo.BaseMutationOptions<
  WipeLandingTablesMutation,
  WipeLandingTablesMutationVariables
>
export const AutoUpdatePnrltDsDocument = gql`
  mutation AutoUpdatePNRLTDs {
    autoUpdatePNRLTDs
  }
`
export type AutoUpdatePnrltDsMutationFn = Apollo.MutationFunction<
  AutoUpdatePnrltDsMutation,
  AutoUpdatePnrltDsMutationVariables
>

/**
 * __useAutoUpdatePnrltDsMutation__
 *
 * To run a mutation, you first call `useAutoUpdatePnrltDsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAutoUpdatePnrltDsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [autoUpdatePnrltDsMutation, { data, loading, error }] = useAutoUpdatePnrltDsMutation({
 *   variables: {
 *   },
 * });
 */
export function useAutoUpdatePnrltDsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AutoUpdatePnrltDsMutation,
    AutoUpdatePnrltDsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AutoUpdatePnrltDsMutation, AutoUpdatePnrltDsMutationVariables>(
    AutoUpdatePnrltDsDocument,
    options
  )
}
export type AutoUpdatePnrltDsMutationHookResult = ReturnType<typeof useAutoUpdatePnrltDsMutation>
export type AutoUpdatePnrltDsMutationResult = Apollo.MutationResult<AutoUpdatePnrltDsMutation>
export type AutoUpdatePnrltDsMutationOptions = Apollo.BaseMutationOptions<
  AutoUpdatePnrltDsMutation,
  AutoUpdatePnrltDsMutationVariables
>
export const DeleteLandingVesselDocument = gql`
  mutation DeleteLandingVessel($imo: Int!) {
    deleteLandingVessel(imo: $imo)
  }
`
export type DeleteLandingVesselMutationFn = Apollo.MutationFunction<
  DeleteLandingVesselMutation,
  DeleteLandingVesselMutationVariables
>

/**
 * __useDeleteLandingVesselMutation__
 *
 * To run a mutation, you first call `useDeleteLandingVesselMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLandingVesselMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLandingVesselMutation, { data, loading, error }] = useDeleteLandingVesselMutation({
 *   variables: {
 *      imo: // value for 'imo'
 *   },
 * });
 */
export function useDeleteLandingVesselMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLandingVesselMutation,
    DeleteLandingVesselMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteLandingVesselMutation, DeleteLandingVesselMutationVariables>(
    DeleteLandingVesselDocument,
    options
  )
}
export type DeleteLandingVesselMutationHookResult = ReturnType<
  typeof useDeleteLandingVesselMutation
>
export type DeleteLandingVesselMutationResult = Apollo.MutationResult<DeleteLandingVesselMutation>
export type DeleteLandingVesselMutationOptions = Apollo.BaseMutationOptions<
  DeleteLandingVesselMutation,
  DeleteLandingVesselMutationVariables
>
export const DeleteErroneousCrewChangeDocument = gql`
  mutation DeleteErroneousCrewChange($imo: Int!, $crewHash: String!) {
    deleteErroneousCrewChanges(imo: $imo, crewHash: $crewHash)
  }
`
export type DeleteErroneousCrewChangeMutationFn = Apollo.MutationFunction<
  DeleteErroneousCrewChangeMutation,
  DeleteErroneousCrewChangeMutationVariables
>

/**
 * __useDeleteErroneousCrewChangeMutation__
 *
 * To run a mutation, you first call `useDeleteErroneousCrewChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteErroneousCrewChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteErroneousCrewChangeMutation, { data, loading, error }] = useDeleteErroneousCrewChangeMutation({
 *   variables: {
 *      imo: // value for 'imo'
 *      crewHash: // value for 'crewHash'
 *   },
 * });
 */
export function useDeleteErroneousCrewChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteErroneousCrewChangeMutation,
    DeleteErroneousCrewChangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteErroneousCrewChangeMutation,
    DeleteErroneousCrewChangeMutationVariables
  >(DeleteErroneousCrewChangeDocument, options)
}
export type DeleteErroneousCrewChangeMutationHookResult = ReturnType<
  typeof useDeleteErroneousCrewChangeMutation
>
export type DeleteErroneousCrewChangeMutationResult =
  Apollo.MutationResult<DeleteErroneousCrewChangeMutation>
export type DeleteErroneousCrewChangeMutationOptions = Apollo.BaseMutationOptions<
  DeleteErroneousCrewChangeMutation,
  DeleteErroneousCrewChangeMutationVariables
>
export const ResetSyncJobDocument = gql`
  mutation ResetSyncJob {
    resetSyncJob
  }
`
export type ResetSyncJobMutationFn = Apollo.MutationFunction<
  ResetSyncJobMutation,
  ResetSyncJobMutationVariables
>

/**
 * __useResetSyncJobMutation__
 *
 * To run a mutation, you first call `useResetSyncJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetSyncJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetSyncJobMutation, { data, loading, error }] = useResetSyncJobMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetSyncJobMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetSyncJobMutation, ResetSyncJobMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResetSyncJobMutation, ResetSyncJobMutationVariables>(
    ResetSyncJobDocument,
    options
  )
}
export type ResetSyncJobMutationHookResult = ReturnType<typeof useResetSyncJobMutation>
export type ResetSyncJobMutationResult = Apollo.MutationResult<ResetSyncJobMutation>
export type ResetSyncJobMutationOptions = Apollo.BaseMutationOptions<
  ResetSyncJobMutation,
  ResetSyncJobMutationVariables
>
export const AddAndLinkManualScheduleDocument = gql`
  mutation AddAndLinkManualSchedule($params: AddManualVesselScheduleInput!) {
    addManualVesselSchedule(params: $params) {
      ...VesselScheduleFields
    }
  }
  ${VesselScheduleFieldsFragmentDoc}
`
export type AddAndLinkManualScheduleMutationFn = Apollo.MutationFunction<
  AddAndLinkManualScheduleMutation,
  AddAndLinkManualScheduleMutationVariables
>

/**
 * __useAddAndLinkManualScheduleMutation__
 *
 * To run a mutation, you first call `useAddAndLinkManualScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAndLinkManualScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAndLinkManualScheduleMutation, { data, loading, error }] = useAddAndLinkManualScheduleMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useAddAndLinkManualScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAndLinkManualScheduleMutation,
    AddAndLinkManualScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddAndLinkManualScheduleMutation,
    AddAndLinkManualScheduleMutationVariables
  >(AddAndLinkManualScheduleDocument, options)
}
export type AddAndLinkManualScheduleMutationHookResult = ReturnType<
  typeof useAddAndLinkManualScheduleMutation
>
export type AddAndLinkManualScheduleMutationResult =
  Apollo.MutationResult<AddAndLinkManualScheduleMutation>
export type AddAndLinkManualScheduleMutationOptions = Apollo.BaseMutationOptions<
  AddAndLinkManualScheduleMutation,
  AddAndLinkManualScheduleMutationVariables
>
export const LinkScheduleToCrewChangeDocument = gql`
  mutation LinkScheduleToCrewChange($vesselScheduleSourceId: String!, $crewHash: String!) {
    linkScheduleToCrewChange(vesselScheduleSourceId: $vesselScheduleSourceId, crewHash: $crewHash)
  }
`
export type LinkScheduleToCrewChangeMutationFn = Apollo.MutationFunction<
  LinkScheduleToCrewChangeMutation,
  LinkScheduleToCrewChangeMutationVariables
>

/**
 * __useLinkScheduleToCrewChangeMutation__
 *
 * To run a mutation, you first call `useLinkScheduleToCrewChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkScheduleToCrewChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkScheduleToCrewChangeMutation, { data, loading, error }] = useLinkScheduleToCrewChangeMutation({
 *   variables: {
 *      vesselScheduleSourceId: // value for 'vesselScheduleSourceId'
 *      crewHash: // value for 'crewHash'
 *   },
 * });
 */
export function useLinkScheduleToCrewChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkScheduleToCrewChangeMutation,
    LinkScheduleToCrewChangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LinkScheduleToCrewChangeMutation,
    LinkScheduleToCrewChangeMutationVariables
  >(LinkScheduleToCrewChangeDocument, options)
}
export type LinkScheduleToCrewChangeMutationHookResult = ReturnType<
  typeof useLinkScheduleToCrewChangeMutation
>
export type LinkScheduleToCrewChangeMutationResult =
  Apollo.MutationResult<LinkScheduleToCrewChangeMutation>
export type LinkScheduleToCrewChangeMutationOptions = Apollo.BaseMutationOptions<
  LinkScheduleToCrewChangeMutation,
  LinkScheduleToCrewChangeMutationVariables
>
export const CreateManualSchedulesFromCsvDocument = gql`
  mutation CreateManualSchedulesFromCsv($file: Upload!) {
    createManualSchedulesFromCsv(file: $file) {
      vessel
      port
      eta
      etd
      imo
      errorMessage
    }
  }
`
export type CreateManualSchedulesFromCsvMutationFn = Apollo.MutationFunction<
  CreateManualSchedulesFromCsvMutation,
  CreateManualSchedulesFromCsvMutationVariables
>

/**
 * __useCreateManualSchedulesFromCsvMutation__
 *
 * To run a mutation, you first call `useCreateManualSchedulesFromCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManualSchedulesFromCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManualSchedulesFromCsvMutation, { data, loading, error }] = useCreateManualSchedulesFromCsvMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useCreateManualSchedulesFromCsvMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateManualSchedulesFromCsvMutation,
    CreateManualSchedulesFromCsvMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateManualSchedulesFromCsvMutation,
    CreateManualSchedulesFromCsvMutationVariables
  >(CreateManualSchedulesFromCsvDocument, options)
}
export type CreateManualSchedulesFromCsvMutationHookResult = ReturnType<
  typeof useCreateManualSchedulesFromCsvMutation
>
export type CreateManualSchedulesFromCsvMutationResult =
  Apollo.MutationResult<CreateManualSchedulesFromCsvMutation>
export type CreateManualSchedulesFromCsvMutationOptions = Apollo.BaseMutationOptions<
  CreateManualSchedulesFromCsvMutation,
  CreateManualSchedulesFromCsvMutationVariables
>
export const LoadUnreviewedPnRsDocument = gql`
  query LoadUnreviewedPNRs {
    unreviewedPNRs {
      ...LandingPNRSummaryFields
    }
  }
  ${LandingPnrSummaryFieldsFragmentDoc}
`

/**
 * __useLoadUnreviewedPnRsQuery__
 *
 * To run a query within a React component, call `useLoadUnreviewedPnRsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadUnreviewedPnRsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadUnreviewedPnRsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadUnreviewedPnRsQuery(
  baseOptions?: Apollo.QueryHookOptions<LoadUnreviewedPnRsQuery, LoadUnreviewedPnRsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoadUnreviewedPnRsQuery, LoadUnreviewedPnRsQueryVariables>(
    LoadUnreviewedPnRsDocument,
    options
  )
}
export function useLoadUnreviewedPnRsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadUnreviewedPnRsQuery,
    LoadUnreviewedPnRsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LoadUnreviewedPnRsQuery, LoadUnreviewedPnRsQueryVariables>(
    LoadUnreviewedPnRsDocument,
    options
  )
}
export function useLoadUnreviewedPnRsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<LoadUnreviewedPnRsQuery, LoadUnreviewedPnRsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LoadUnreviewedPnRsQuery, LoadUnreviewedPnRsQueryVariables>(
    LoadUnreviewedPnRsDocument,
    options
  )
}
export type LoadUnreviewedPnRsQueryHookResult = ReturnType<typeof useLoadUnreviewedPnRsQuery>
export type LoadUnreviewedPnRsLazyQueryHookResult = ReturnType<
  typeof useLoadUnreviewedPnRsLazyQuery
>
export type LoadUnreviewedPnRsSuspenseQueryHookResult = ReturnType<
  typeof useLoadUnreviewedPnRsSuspenseQuery
>
export type LoadUnreviewedPnRsQueryResult = Apollo.QueryResult<
  LoadUnreviewedPnRsQuery,
  LoadUnreviewedPnRsQueryVariables
>
export const LoadReviewedPnRsDocument = gql`
  query LoadReviewedPNRs {
    recentlyViewedPNRs {
      ...LandingPNRSummaryFields
    }
  }
  ${LandingPnrSummaryFieldsFragmentDoc}
`

/**
 * __useLoadReviewedPnRsQuery__
 *
 * To run a query within a React component, call `useLoadReviewedPnRsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadReviewedPnRsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadReviewedPnRsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadReviewedPnRsQuery(
  baseOptions?: Apollo.QueryHookOptions<LoadReviewedPnRsQuery, LoadReviewedPnRsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoadReviewedPnRsQuery, LoadReviewedPnRsQueryVariables>(
    LoadReviewedPnRsDocument,
    options
  )
}
export function useLoadReviewedPnRsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LoadReviewedPnRsQuery, LoadReviewedPnRsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LoadReviewedPnRsQuery, LoadReviewedPnRsQueryVariables>(
    LoadReviewedPnRsDocument,
    options
  )
}
export function useLoadReviewedPnRsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<LoadReviewedPnRsQuery, LoadReviewedPnRsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LoadReviewedPnRsQuery, LoadReviewedPnRsQueryVariables>(
    LoadReviewedPnRsDocument,
    options
  )
}
export type LoadReviewedPnRsQueryHookResult = ReturnType<typeof useLoadReviewedPnRsQuery>
export type LoadReviewedPnRsLazyQueryHookResult = ReturnType<typeof useLoadReviewedPnRsLazyQuery>
export type LoadReviewedPnRsSuspenseQueryHookResult = ReturnType<
  typeof useLoadReviewedPnRsSuspenseQuery
>
export type LoadReviewedPnRsQueryResult = Apollo.QueryResult<
  LoadReviewedPnRsQuery,
  LoadReviewedPnRsQueryVariables
>
export const LoadPnrInfoDocument = gql`
  query LoadPnrInfo($params: PnrInfoParams!) {
    pnrInfo(params: $params) {
      marketingCarriers
      originalLTD
      fares {
        amount
        tax
        currency
        lastTicketingDate
        baggageDetails {
          freeBagAllowance
          freeBagAllowanceType
        }
      }
      possibleLtds {
        source
        originalLtd
        timezone
        offset
        score
        utcLtd
      }
      rawPnr
      officeId
    }
  }
`

/**
 * __useLoadPnrInfoQuery__
 *
 * To run a query within a React component, call `useLoadPnrInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadPnrInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadPnrInfoQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useLoadPnrInfoQuery(
  baseOptions: Apollo.QueryHookOptions<LoadPnrInfoQuery, LoadPnrInfoQueryVariables> &
    ({ variables: LoadPnrInfoQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoadPnrInfoQuery, LoadPnrInfoQueryVariables>(LoadPnrInfoDocument, options)
}
export function useLoadPnrInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LoadPnrInfoQuery, LoadPnrInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LoadPnrInfoQuery, LoadPnrInfoQueryVariables>(
    LoadPnrInfoDocument,
    options
  )
}
export function useLoadPnrInfoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<LoadPnrInfoQuery, LoadPnrInfoQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LoadPnrInfoQuery, LoadPnrInfoQueryVariables>(
    LoadPnrInfoDocument,
    options
  )
}
export type LoadPnrInfoQueryHookResult = ReturnType<typeof useLoadPnrInfoQuery>
export type LoadPnrInfoLazyQueryHookResult = ReturnType<typeof useLoadPnrInfoLazyQuery>
export type LoadPnrInfoSuspenseQueryHookResult = ReturnType<typeof useLoadPnrInfoSuspenseQuery>
export type LoadPnrInfoQueryResult = Apollo.QueryResult<LoadPnrInfoQuery, LoadPnrInfoQueryVariables>
export const AddPnrSelectionDataDocument = gql`
  mutation addPnrSelectionData(
    $pnr: String!
    $xml: String!
    $selectedLtd: DateTime!
    $possibleLtdSelections: [PossiblePnrLtdSelection!]!
  ) {
    addPnrSelectionData(
      pnr: $pnr
      xml: $xml
      selectedLtd: $selectedLtd
      possibleLtdSelections: $possibleLtdSelections
    )
  }
`
export type AddPnrSelectionDataMutationFn = Apollo.MutationFunction<
  AddPnrSelectionDataMutation,
  AddPnrSelectionDataMutationVariables
>

/**
 * __useAddPnrSelectionDataMutation__
 *
 * To run a mutation, you first call `useAddPnrSelectionDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPnrSelectionDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPnrSelectionDataMutation, { data, loading, error }] = useAddPnrSelectionDataMutation({
 *   variables: {
 *      pnr: // value for 'pnr'
 *      xml: // value for 'xml'
 *      selectedLtd: // value for 'selectedLtd'
 *      possibleLtdSelections: // value for 'possibleLtdSelections'
 *   },
 * });
 */
export function useAddPnrSelectionDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPnrSelectionDataMutation,
    AddPnrSelectionDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddPnrSelectionDataMutation, AddPnrSelectionDataMutationVariables>(
    AddPnrSelectionDataDocument,
    options
  )
}
export type AddPnrSelectionDataMutationHookResult = ReturnType<
  typeof useAddPnrSelectionDataMutation
>
export type AddPnrSelectionDataMutationResult = Apollo.MutationResult<AddPnrSelectionDataMutation>
export type AddPnrSelectionDataMutationOptions = Apollo.BaseMutationOptions<
  AddPnrSelectionDataMutation,
  AddPnrSelectionDataMutationVariables
>
export const UpdatePnrltdDocument = gql`
  mutation updatePNRLTD($pnr: String!, $ltd: DateTime!) {
    updatePNRLTD(pnr: $pnr, ltd: $ltd)
  }
`
export type UpdatePnrltdMutationFn = Apollo.MutationFunction<
  UpdatePnrltdMutation,
  UpdatePnrltdMutationVariables
>

/**
 * __useUpdatePnrltdMutation__
 *
 * To run a mutation, you first call `useUpdatePnrltdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePnrltdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePnrltdMutation, { data, loading, error }] = useUpdatePnrltdMutation({
 *   variables: {
 *      pnr: // value for 'pnr'
 *      ltd: // value for 'ltd'
 *   },
 * });
 */
export function useUpdatePnrltdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePnrltdMutation, UpdatePnrltdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePnrltdMutation, UpdatePnrltdMutationVariables>(
    UpdatePnrltdDocument,
    options
  )
}
export type UpdatePnrltdMutationHookResult = ReturnType<typeof useUpdatePnrltdMutation>
export type UpdatePnrltdMutationResult = Apollo.MutationResult<UpdatePnrltdMutation>
export type UpdatePnrltdMutationOptions = Apollo.BaseMutationOptions<
  UpdatePnrltdMutation,
  UpdatePnrltdMutationVariables
>
export const NotifyLtdNearbyDocument = gql`
  mutation notifyLTDNearby($pnr: String!) {
    notifyLTDNearby(pnr: $pnr)
  }
`
export type NotifyLtdNearbyMutationFn = Apollo.MutationFunction<
  NotifyLtdNearbyMutation,
  NotifyLtdNearbyMutationVariables
>

/**
 * __useNotifyLtdNearbyMutation__
 *
 * To run a mutation, you first call `useNotifyLtdNearbyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyLtdNearbyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyLtdNearbyMutation, { data, loading, error }] = useNotifyLtdNearbyMutation({
 *   variables: {
 *      pnr: // value for 'pnr'
 *   },
 * });
 */
export function useNotifyLtdNearbyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotifyLtdNearbyMutation,
    NotifyLtdNearbyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<NotifyLtdNearbyMutation, NotifyLtdNearbyMutationVariables>(
    NotifyLtdNearbyDocument,
    options
  )
}
export type NotifyLtdNearbyMutationHookResult = ReturnType<typeof useNotifyLtdNearbyMutation>
export type NotifyLtdNearbyMutationResult = Apollo.MutationResult<NotifyLtdNearbyMutation>
export type NotifyLtdNearbyMutationOptions = Apollo.BaseMutationOptions<
  NotifyLtdNearbyMutation,
  NotifyLtdNearbyMutationVariables
>
export const ReplaceVesselScheduleDocument = gql`
  mutation ReplaceVesselSchedule($scheduleId: UUID!, $replacementScheduleId: UUID!) {
    replaceVesselSchedule(scheduleId: $scheduleId, replacementScheduleId: $replacementScheduleId)
  }
`
export type ReplaceVesselScheduleMutationFn = Apollo.MutationFunction<
  ReplaceVesselScheduleMutation,
  ReplaceVesselScheduleMutationVariables
>

/**
 * __useReplaceVesselScheduleMutation__
 *
 * To run a mutation, you first call `useReplaceVesselScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceVesselScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceVesselScheduleMutation, { data, loading, error }] = useReplaceVesselScheduleMutation({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *      replacementScheduleId: // value for 'replacementScheduleId'
 *   },
 * });
 */
export function useReplaceVesselScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReplaceVesselScheduleMutation,
    ReplaceVesselScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ReplaceVesselScheduleMutation, ReplaceVesselScheduleMutationVariables>(
    ReplaceVesselScheduleDocument,
    options
  )
}
export type ReplaceVesselScheduleMutationHookResult = ReturnType<
  typeof useReplaceVesselScheduleMutation
>
export type ReplaceVesselScheduleMutationResult =
  Apollo.MutationResult<ReplaceVesselScheduleMutation>
export type ReplaceVesselScheduleMutationOptions = Apollo.BaseMutationOptions<
  ReplaceVesselScheduleMutation,
  ReplaceVesselScheduleMutationVariables
>
