/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type LoadTenantsForDomainQueryVariables = Types.Exact<{ [key: string]: never }>

export type LoadTenantsForDomainQuery = { tenantsForDomain: Array<{ id: string; name: string }> }

export type LoadCurrentTenantQueryVariables = Types.Exact<{ [key: string]: never }>

export type LoadCurrentTenantQuery = {
  currentTenant: {
    id: string
    preferences: {
      id: string
      preferredCurrency: string
      includeTravelOfficeCommissionForFlightPriceComparison: boolean
    }
  }
}

export type LoadTenantCrewOperatorsQueryVariables = Types.Exact<{ [key: string]: never }>

export type LoadTenantCrewOperatorsQuery = {
  currentTenant: {
    id: string
    crewOperators: Array<{ id: string; lastName: string | null; firstName: string | null }>
  }
}

export type LoadTenantCrewSupervisorsQueryVariables = Types.Exact<{ [key: string]: never }>

export type LoadTenantCrewSupervisorsQuery = {
  currentTenant: {
    id: string
    crewSupervisors: Array<{ id: string; lastName: string | null; firstName: string | null }>
  }
}

export type LoadTenantTravelAgentQueryVariables = Types.Exact<{ [key: string]: never }>

export type LoadTenantTravelAgentQuery = {
  currentTenant: {
    id: string
    preferences: {
      id: string
      travelAgent: {
        id: string
        email: string
        lastName: string | null
        firstName: string | null
        phoneNumber: string | null
      } | null
    }
  }
}

export type LoadTenantTravelOfficeConfigsQueryVariables = Types.Exact<{
  includeInactive?: Types.InputMaybe<Types.Scalars['Boolean']['input']>
}>

export type LoadTenantTravelOfficeConfigsQuery = {
  currentTenant: {
    id: string
    travelOfficeConfigurations: Array<{
      id: string
      agencyName: string
      commission: number
      commissionPercentage: number
      currency: string
      officeId: string
      emailPrimary: string
      active: boolean
      phonePrimary: string
      updatedAt: string
    }>
  }
}

export const LoadTenantsForDomainDocument = gql`
  query LoadTenantsForDomain {
    tenantsForDomain {
      id
      name
    }
  }
`

/**
 * __useLoadTenantsForDomainQuery__
 *
 * To run a query within a React component, call `useLoadTenantsForDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadTenantsForDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadTenantsForDomainQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadTenantsForDomainQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadTenantsForDomainQuery,
    LoadTenantsForDomainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoadTenantsForDomainQuery, LoadTenantsForDomainQueryVariables>(
    LoadTenantsForDomainDocument,
    options
  )
}
export function useLoadTenantsForDomainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadTenantsForDomainQuery,
    LoadTenantsForDomainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LoadTenantsForDomainQuery, LoadTenantsForDomainQueryVariables>(
    LoadTenantsForDomainDocument,
    options
  )
}
export function useLoadTenantsForDomainSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<LoadTenantsForDomainQuery, LoadTenantsForDomainQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LoadTenantsForDomainQuery, LoadTenantsForDomainQueryVariables>(
    LoadTenantsForDomainDocument,
    options
  )
}
export type LoadTenantsForDomainQueryHookResult = ReturnType<typeof useLoadTenantsForDomainQuery>
export type LoadTenantsForDomainLazyQueryHookResult = ReturnType<
  typeof useLoadTenantsForDomainLazyQuery
>
export type LoadTenantsForDomainSuspenseQueryHookResult = ReturnType<
  typeof useLoadTenantsForDomainSuspenseQuery
>
export type LoadTenantsForDomainQueryResult = Apollo.QueryResult<
  LoadTenantsForDomainQuery,
  LoadTenantsForDomainQueryVariables
>
export const LoadCurrentTenantDocument = gql`
  query LoadCurrentTenant {
    currentTenant {
      id
      preferences {
        id
        preferredCurrency
        includeTravelOfficeCommissionForFlightPriceComparison
      }
    }
  }
`

/**
 * __useLoadCurrentTenantQuery__
 *
 * To run a query within a React component, call `useLoadCurrentTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCurrentTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCurrentTenantQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadCurrentTenantQuery(
  baseOptions?: Apollo.QueryHookOptions<LoadCurrentTenantQuery, LoadCurrentTenantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoadCurrentTenantQuery, LoadCurrentTenantQueryVariables>(
    LoadCurrentTenantDocument,
    options
  )
}
export function useLoadCurrentTenantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LoadCurrentTenantQuery, LoadCurrentTenantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LoadCurrentTenantQuery, LoadCurrentTenantQueryVariables>(
    LoadCurrentTenantDocument,
    options
  )
}
export function useLoadCurrentTenantSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<LoadCurrentTenantQuery, LoadCurrentTenantQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LoadCurrentTenantQuery, LoadCurrentTenantQueryVariables>(
    LoadCurrentTenantDocument,
    options
  )
}
export type LoadCurrentTenantQueryHookResult = ReturnType<typeof useLoadCurrentTenantQuery>
export type LoadCurrentTenantLazyQueryHookResult = ReturnType<typeof useLoadCurrentTenantLazyQuery>
export type LoadCurrentTenantSuspenseQueryHookResult = ReturnType<
  typeof useLoadCurrentTenantSuspenseQuery
>
export type LoadCurrentTenantQueryResult = Apollo.QueryResult<
  LoadCurrentTenantQuery,
  LoadCurrentTenantQueryVariables
>
export const LoadTenantCrewOperatorsDocument = gql`
  query LoadTenantCrewOperators {
    currentTenant {
      id
      crewOperators {
        id
        lastName
        firstName
      }
    }
  }
`

/**
 * __useLoadTenantCrewOperatorsQuery__
 *
 * To run a query within a React component, call `useLoadTenantCrewOperatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadTenantCrewOperatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadTenantCrewOperatorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadTenantCrewOperatorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadTenantCrewOperatorsQuery,
    LoadTenantCrewOperatorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoadTenantCrewOperatorsQuery, LoadTenantCrewOperatorsQueryVariables>(
    LoadTenantCrewOperatorsDocument,
    options
  )
}
export function useLoadTenantCrewOperatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadTenantCrewOperatorsQuery,
    LoadTenantCrewOperatorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LoadTenantCrewOperatorsQuery, LoadTenantCrewOperatorsQueryVariables>(
    LoadTenantCrewOperatorsDocument,
    options
  )
}
export function useLoadTenantCrewOperatorsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadTenantCrewOperatorsQuery,
        LoadTenantCrewOperatorsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    LoadTenantCrewOperatorsQuery,
    LoadTenantCrewOperatorsQueryVariables
  >(LoadTenantCrewOperatorsDocument, options)
}
export type LoadTenantCrewOperatorsQueryHookResult = ReturnType<
  typeof useLoadTenantCrewOperatorsQuery
>
export type LoadTenantCrewOperatorsLazyQueryHookResult = ReturnType<
  typeof useLoadTenantCrewOperatorsLazyQuery
>
export type LoadTenantCrewOperatorsSuspenseQueryHookResult = ReturnType<
  typeof useLoadTenantCrewOperatorsSuspenseQuery
>
export type LoadTenantCrewOperatorsQueryResult = Apollo.QueryResult<
  LoadTenantCrewOperatorsQuery,
  LoadTenantCrewOperatorsQueryVariables
>
export const LoadTenantCrewSupervisorsDocument = gql`
  query LoadTenantCrewSupervisors {
    currentTenant {
      id
      crewSupervisors {
        id
        lastName
        firstName
      }
    }
  }
`

/**
 * __useLoadTenantCrewSupervisorsQuery__
 *
 * To run a query within a React component, call `useLoadTenantCrewSupervisorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadTenantCrewSupervisorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadTenantCrewSupervisorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadTenantCrewSupervisorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadTenantCrewSupervisorsQuery,
    LoadTenantCrewSupervisorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoadTenantCrewSupervisorsQuery, LoadTenantCrewSupervisorsQueryVariables>(
    LoadTenantCrewSupervisorsDocument,
    options
  )
}
export function useLoadTenantCrewSupervisorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadTenantCrewSupervisorsQuery,
    LoadTenantCrewSupervisorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LoadTenantCrewSupervisorsQuery,
    LoadTenantCrewSupervisorsQueryVariables
  >(LoadTenantCrewSupervisorsDocument, options)
}
export function useLoadTenantCrewSupervisorsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadTenantCrewSupervisorsQuery,
        LoadTenantCrewSupervisorsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    LoadTenantCrewSupervisorsQuery,
    LoadTenantCrewSupervisorsQueryVariables
  >(LoadTenantCrewSupervisorsDocument, options)
}
export type LoadTenantCrewSupervisorsQueryHookResult = ReturnType<
  typeof useLoadTenantCrewSupervisorsQuery
>
export type LoadTenantCrewSupervisorsLazyQueryHookResult = ReturnType<
  typeof useLoadTenantCrewSupervisorsLazyQuery
>
export type LoadTenantCrewSupervisorsSuspenseQueryHookResult = ReturnType<
  typeof useLoadTenantCrewSupervisorsSuspenseQuery
>
export type LoadTenantCrewSupervisorsQueryResult = Apollo.QueryResult<
  LoadTenantCrewSupervisorsQuery,
  LoadTenantCrewSupervisorsQueryVariables
>
export const LoadTenantTravelAgentDocument = gql`
  query LoadTenantTravelAgent {
    currentTenant {
      id
      preferences {
        id
        travelAgent {
          id
          email
          lastName
          firstName
          phoneNumber
        }
      }
    }
  }
`

/**
 * __useLoadTenantTravelAgentQuery__
 *
 * To run a query within a React component, call `useLoadTenantTravelAgentQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadTenantTravelAgentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadTenantTravelAgentQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadTenantTravelAgentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadTenantTravelAgentQuery,
    LoadTenantTravelAgentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoadTenantTravelAgentQuery, LoadTenantTravelAgentQueryVariables>(
    LoadTenantTravelAgentDocument,
    options
  )
}
export function useLoadTenantTravelAgentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadTenantTravelAgentQuery,
    LoadTenantTravelAgentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LoadTenantTravelAgentQuery, LoadTenantTravelAgentQueryVariables>(
    LoadTenantTravelAgentDocument,
    options
  )
}
export function useLoadTenantTravelAgentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadTenantTravelAgentQuery,
        LoadTenantTravelAgentQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LoadTenantTravelAgentQuery, LoadTenantTravelAgentQueryVariables>(
    LoadTenantTravelAgentDocument,
    options
  )
}
export type LoadTenantTravelAgentQueryHookResult = ReturnType<typeof useLoadTenantTravelAgentQuery>
export type LoadTenantTravelAgentLazyQueryHookResult = ReturnType<
  typeof useLoadTenantTravelAgentLazyQuery
>
export type LoadTenantTravelAgentSuspenseQueryHookResult = ReturnType<
  typeof useLoadTenantTravelAgentSuspenseQuery
>
export type LoadTenantTravelAgentQueryResult = Apollo.QueryResult<
  LoadTenantTravelAgentQuery,
  LoadTenantTravelAgentQueryVariables
>
export const LoadTenantTravelOfficeConfigsDocument = gql`
  query LoadTenantTravelOfficeConfigs($includeInactive: Boolean) {
    currentTenant {
      id
      travelOfficeConfigurations(includeInactive: $includeInactive) {
        id
        agencyName
        commission
        commissionPercentage
        currency
        officeId
        emailPrimary
        active
        phonePrimary
        updatedAt
      }
    }
  }
`

/**
 * __useLoadTenantTravelOfficeConfigsQuery__
 *
 * To run a query within a React component, call `useLoadTenantTravelOfficeConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadTenantTravelOfficeConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadTenantTravelOfficeConfigsQuery({
 *   variables: {
 *      includeInactive: // value for 'includeInactive'
 *   },
 * });
 */
export function useLoadTenantTravelOfficeConfigsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadTenantTravelOfficeConfigsQuery,
    LoadTenantTravelOfficeConfigsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LoadTenantTravelOfficeConfigsQuery,
    LoadTenantTravelOfficeConfigsQueryVariables
  >(LoadTenantTravelOfficeConfigsDocument, options)
}
export function useLoadTenantTravelOfficeConfigsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadTenantTravelOfficeConfigsQuery,
    LoadTenantTravelOfficeConfigsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LoadTenantTravelOfficeConfigsQuery,
    LoadTenantTravelOfficeConfigsQueryVariables
  >(LoadTenantTravelOfficeConfigsDocument, options)
}
export function useLoadTenantTravelOfficeConfigsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadTenantTravelOfficeConfigsQuery,
        LoadTenantTravelOfficeConfigsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    LoadTenantTravelOfficeConfigsQuery,
    LoadTenantTravelOfficeConfigsQueryVariables
  >(LoadTenantTravelOfficeConfigsDocument, options)
}
export type LoadTenantTravelOfficeConfigsQueryHookResult = ReturnType<
  typeof useLoadTenantTravelOfficeConfigsQuery
>
export type LoadTenantTravelOfficeConfigsLazyQueryHookResult = ReturnType<
  typeof useLoadTenantTravelOfficeConfigsLazyQuery
>
export type LoadTenantTravelOfficeConfigsSuspenseQueryHookResult = ReturnType<
  typeof useLoadTenantTravelOfficeConfigsSuspenseQuery
>
export type LoadTenantTravelOfficeConfigsQueryResult = Apollo.QueryResult<
  LoadTenantTravelOfficeConfigsQuery,
  LoadTenantTravelOfficeConfigsQueryVariables
>
