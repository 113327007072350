/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type SearchSeaportsQueryVariables = Types.Exact<{
  search: Types.Scalars['String']['input']
}>

export type SearchSeaportsQuery = {
  seaports: Array<{
    id: string
    name: string
    nameAlpha2: string | null
    alternateNames: Array<string>
    countryAlpha2: string
    locode: string
    latitude: number
    longitude: number
    timezoneOlson: string | null
  }>
}

export type SeaportFieldsFragment = {
  id: string
  name: string
  nameAlpha2: string | null
  alternateNames: Array<string>
  countryAlpha2: string
  locode: string
  latitude: number
  longitude: number
  timezoneOlson: string | null
}

export const SeaportFieldsFragmentDoc = gql`
  fragment SeaportFields on Seaport {
    id
    name
    nameAlpha2
    alternateNames
    countryAlpha2
    locode
    latitude
    longitude
    timezoneOlson
  }
`
export const SearchSeaportsDocument = gql`
  query SearchSeaports($search: String!) {
    seaports(search: $search) {
      ...SeaportFields
    }
  }
  ${SeaportFieldsFragmentDoc}
`

/**
 * __useSearchSeaportsQuery__
 *
 * To run a query within a React component, call `useSearchSeaportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSeaportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSeaportsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchSeaportsQuery(
  baseOptions: Apollo.QueryHookOptions<SearchSeaportsQuery, SearchSeaportsQueryVariables> &
    ({ variables: SearchSeaportsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchSeaportsQuery, SearchSeaportsQueryVariables>(
    SearchSeaportsDocument,
    options
  )
}
export function useSearchSeaportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchSeaportsQuery, SearchSeaportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchSeaportsQuery, SearchSeaportsQueryVariables>(
    SearchSeaportsDocument,
    options
  )
}
export function useSearchSeaportsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SearchSeaportsQuery, SearchSeaportsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SearchSeaportsQuery, SearchSeaportsQueryVariables>(
    SearchSeaportsDocument,
    options
  )
}
export type SearchSeaportsQueryHookResult = ReturnType<typeof useSearchSeaportsQuery>
export type SearchSeaportsLazyQueryHookResult = ReturnType<typeof useSearchSeaportsLazyQuery>
export type SearchSeaportsSuspenseQueryHookResult = ReturnType<
  typeof useSearchSeaportsSuspenseQuery
>
export type SearchSeaportsQueryResult = Apollo.QueryResult<
  SearchSeaportsQuery,
  SearchSeaportsQueryVariables
>
