import clsx from 'clsx'
import { ClassAttributes, createElement, TdHTMLAttributes } from 'react'
import { TableVariant } from './Table'

type TableCellProps = TdHTMLAttributes<HTMLTableCellElement> &
  ClassAttributes<HTMLTableCellElement> & {
    tagName?: 'td' | 'th'
    shrink?: boolean
    variant?: TableVariant
  }

function TableCell({
  tagName = 'td',
  shrink = false,
  variant = 'standalone',
  ...otherProps
}: TableCellProps): JSX.Element {
  return createElement(tagName, {
    ...otherProps,
    className: clsx(
      variant === 'inline' && 'py-2 px-0',
      variant !== 'inline' && 'px-4',
      variant !== 'inline' && tagName === 'th' && 'py-2',
      variant !== 'inline' && tagName === 'td' && 'py-4',
      shrink && 'w-0',
      otherProps.className
    ),
  })
}

export default TableCell
