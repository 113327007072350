/* eslint-disable */
import { Role as UserRole } from '@tilla/backend/shared'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  BigInt: { input: string; output: string }
  Date: { input: Date; output: Date }
  DateTime: { input: string; output: string }
  JSONObject: { input: unknown; output: unknown }
  UUID: { input: string; output: string }
  Upload: { input: any; output: any }
  Void: { input: void; output: void }
}

export enum ActiveCrewOperatorType {
  Backup = 'Backup',
  Primary = 'Primary',
}

export enum ActiveCrewSupervisorType {
  Backup = 'Backup',
  Primary = 'Primary',
}

export type AddCrewChangeVisaRequirementsInput = {
  additionalComments: Scalars['String']['input']
  crewChangeSeamenId: Scalars['UUID']['input']
  region: Scalars['String']['input']
  required: Scalars['Boolean']['input']
  requiredOn: VisaOrCovidTestRequiredOn
}

export type AddManualVesselScheduleInput = {
  ETA?: InputMaybe<Scalars['DateTime']['input']>
  ETB?: InputMaybe<Scalars['DateTime']['input']>
  ETD?: InputMaybe<Scalars['DateTime']['input']>
  crewHash: Scalars['String']['input']
  seaportId: Scalars['UUID']['input']
  source: VesselScheduleSource
  vesselId: Scalars['UUID']['input']
}

export type AddPortPlanningScenarioInput = {
  newSchedule?: InputMaybe<PortPlanningScenarioNewScheduleInput>
  seaportId: Scalars['UUID']['input']
  vesselId: Scalars['UUID']['input']
  vesselScheduleId?: InputMaybe<Scalars['UUID']['input']>
}

export type AddVesselScheduleInput = {
  ETA?: InputMaybe<Scalars['DateTime']['input']>
  ETB?: InputMaybe<Scalars['DateTime']['input']>
  ETD?: InputMaybe<Scalars['DateTime']['input']>
  seaportId: Scalars['UUID']['input']
  source: VesselScheduleSource
  vesselId: Scalars['UUID']['input']
}

export type AdditionalCrewOperatorForVessel = {
  crewOperator: SafePartialUser
}

export type Airline = {
  createdAt: Scalars['DateTime']['output']
  iata: Scalars['String']['output']
  id: Scalars['UUID']['output']
  logo: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type AirlineInput = {
  iata: Scalars['String']['input']
  id: Scalars['UUID']['input']
  logo?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type Airport = {
  alternativeIatas: Array<Scalars['String']['output']>
  city: Scalars['String']['output']
  countryAlpha2: Scalars['String']['output']
  iata: Scalars['String']['output']
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  timezoneOlson: Maybe<Scalars['String']['output']>
}

export type AutoSearchFlightsFilters = {
  /** Date of arrival/departure depending on the time specifier. If null we'll use default based on seamen travel windows */
  date: Scalars['String']['input']
  /** Whether we should default to default search logic if provided filters do not return any results */
  dynamicFilters: Scalars['Boolean']['input']
  maximumLayoverInMinutes: Scalars['Int']['input']
  minimumLayoverInMinutes: Scalars['Int']['input']
  refundableOnly: Scalars['Boolean']['input']
  timeRange: FlightSearchTimeRange
}

export type AutoSearchFlightsParams = {
  alternativeArrivals?: InputMaybe<Array<Scalars['String']['input']>>
  alternativeDepartures?: InputMaybe<Array<Scalars['String']['input']>>
  arrivals: Array<Scalars['String']['input']>
  bucketId: Scalars['UUID']['input']
  crewChangeSeamanId: Scalars['UUID']['input']
  departures: Array<Scalars['String']['input']>
  filters?: InputMaybe<AutoSearchFlightsFilters>
  role: CrewChangeRole
  seamanId: Scalars['UUID']['input']
  splitTicketingRecommendationsOnly?: InputMaybe<Scalars['Boolean']['input']>
  timeSpecifier: TimeSpecifier
}

export type BaggageDetails = {
  freeBagAllowance: Maybe<Scalars['Float']['output']>
  freeBagAllowanceType: Maybe<FreeBagAllowanceType>
}

export type BaggageDetailsInput = {
  freeBagAllowance?: InputMaybe<Scalars['Float']['input']>
  freeBagAllowanceType?: InputMaybe<FreeBagAllowanceType>
}

export type BookUnselectedInput = {
  bookingCreationSource?: InputMaybe<FlightBookingCreationSource>
  bucketId: Scalars['UUID']['input']
  flightId: Scalars['UUID']['input']
  offerId: Scalars['UUID']['input']
  seamenIds: Array<Scalars['UUID']['input']>
}

export enum BookingCancellationAction {
  CancelBookingDeferral = 'CancelBookingDeferral',
  CancelPnr = 'CancelPnr',
  CancelTicketingDeferral = 'CancelTicketingDeferral',
  CancellationUnavailable = 'CancellationUnavailable',
  Deactivate = 'Deactivate',
  DeferCancellation = 'DeferCancellation',
  NoCancellationRequired = 'NoCancellationRequired',
  Refund = 'Refund',
  VoidTicket = 'VoidTicket',
}

export type BookingHistoryRow = {
  arrival: Scalars['String']['output']
  date: Scalars['DateTime']['output']
  departure: Scalars['String']['output']
  finalOfferAmount: Scalars['Int']['output']
  finalOfferCurrency: Scalars['String']['output']
  name: Scalars['String']['output']
  pnr: Scalars['String']['output']
  refundCurrency: Maybe<Scalars['String']['output']>
  refundedAmount: Maybe<Scalars['Int']['output']>
  route: Scalars['String']['output']
  status: FlightBookingStatus
  travelOfficeConfigurationId: Scalars['String']['output']
  travelTeam: Scalars['String']['output']
}

export type BookingInfo = {
  canRebook: Scalars['Boolean']['output']
  cancellationAction: BookingCancellationAction
  currentCheaperOfferNotifications: Array<CheaperFlightOfferNotification>
}

export type CancelEvaluateScenarioInput = {
  scenarioIds: Array<Scalars['UUID']['input']>
}

export type ChangedSegmentsNotification = {
  change: FlightSegmentChange
  notification: Notification
}

export type CheaperFlightOfferNotification = {
  cheaperOffer: FlightOfferNotification
  createdAt: Scalars['DateTime']['output']
  dismissed: Scalars['Boolean']['output']
  id: Scalars['UUID']['output']
}

export type CheaperOffer = {
  flightBooking: FlightBooking
  flightOffer: FlightOffer
}

export type CheaperOfferNotification = {
  cheaperOffer: CheaperOffer
  notification: Notification
  seaman: Seaman
}

export enum CommunicationType {
  Email = 'Email',
  Fax = 'Fax',
  Mobile = 'Mobile',
  Phone = 'Phone',
  Unknown = 'Unknown',
}

export type ConnectionRecommendation = {
  connectingAirports: Array<Airport>
  fromIata: Scalars['String']['output']
  toIata: Scalars['String']['output']
}

export type CovidRestrictionDocuments = {
  additionalComments: Scalars['String']['output']
  required: Maybe<Scalars['Boolean']['output']>
}

export type CovidRestrictionDocumentsInput = {
  additionalComments: Scalars['String']['input']
  required?: InputMaybe<Scalars['Boolean']['input']>
}

export type CovidRestrictionForLeg = {
  arrival: Maybe<Scalars['String']['output']>
  departure: Maybe<Scalars['String']['output']>
  testRequirements: Array<CovidTestRequirement>
}

export type CovidRestrictionOffSignersRestrictions = {
  pcrTesting: CovidRestrictionOffSignersRestrictionsPcrTesting
  portStay: CovidRestrictionPortStay
  quarantine: CovidRestrictionOffSignersRestrictionsQuarantine
}

export type CovidRestrictionOffSignersRestrictionsInput = {
  pcrTesting: CovidRestrictionOffSignersRestrictionsPcrTestingInput
  portStay: CovidRestrictionPortStayInput
  quarantine: CovidRestrictionOffSignersRestrictionsQuarantineInput
}

export type CovidRestrictionOffSignersRestrictionsPcrTesting = {
  disembarkAllowed: Maybe<Scalars['Boolean']['output']>
  mandatory: Maybe<Scalars['Boolean']['output']>
  vesselDepartureBeforeResults: Maybe<Scalars['Boolean']['output']>
}

export type CovidRestrictionOffSignersRestrictionsPcrTestingInput = {
  disembarkAllowed?: InputMaybe<Scalars['Boolean']['input']>
  mandatory?: InputMaybe<Scalars['Boolean']['input']>
  vesselDepartureBeforeResults?: InputMaybe<Scalars['Boolean']['input']>
}

export type CovidRestrictionOffSignersRestrictionsQuarantine = {
  duration: Scalars['Float']['output']
  mandatory: Maybe<Scalars['Boolean']['output']>
}

export type CovidRestrictionOffSignersRestrictionsQuarantineInput = {
  duration: Scalars['Float']['input']
  mandatory?: InputMaybe<Scalars['Boolean']['input']>
}

export type CovidRestrictionOnSignersRestrictions = {
  documents: CovidRestrictionDocuments
  pcrTesting: CovidRestrictionOnSignersRestrictionsPcrTesting
  portStay: CovidRestrictionPortStay
  quarantine: CovidRestrictionOnSignersRestrictionsQuarantine
}

export type CovidRestrictionOnSignersRestrictionsInput = {
  documents: CovidRestrictionDocumentsInput
  pcrTesting: CovidRestrictionOnSignersRestrictionsPcrTestingInput
  portStay: CovidRestrictionPortStayInput
  quarantine: CovidRestrictionOnSignersRestrictionsQuarantineInput
}

export type CovidRestrictionOnSignersRestrictionsPcrTesting = {
  additionalComments: Scalars['String']['output']
  inHomeCountry: Maybe<Scalars['Boolean']['output']>
  mandatory: Maybe<Scalars['Boolean']['output']>
}

export type CovidRestrictionOnSignersRestrictionsPcrTestingInput = {
  additionalComments: Scalars['String']['input']
  inHomeCountry?: InputMaybe<Scalars['Boolean']['input']>
  mandatory?: InputMaybe<Scalars['Boolean']['input']>
}

export type CovidRestrictionOnSignersRestrictionsQuarantine = {
  duration: Scalars['Float']['output']
  mandatory: Maybe<Scalars['Boolean']['output']>
  mandatoryOn: Maybe<CovidRestrictionOnSignersRestrictionsQuarantineMandatoryType>
}

export type CovidRestrictionOnSignersRestrictionsQuarantineInput = {
  duration: Scalars['Float']['input']
  mandatory?: InputMaybe<Scalars['Boolean']['input']>
  mandatoryOn?: InputMaybe<CovidRestrictionOnSignersRestrictionsQuarantineMandatoryTypeInput>
}

export enum CovidRestrictionOnSignersRestrictionsQuarantineMandatoryType {
  OnArrival = 'OnArrival',
  PreArrival = 'PreArrival',
}

export enum CovidRestrictionOnSignersRestrictionsQuarantineMandatoryTypeInput {
  OnArrival = 'OnArrival',
  PreArrival = 'PreArrival',
}

export type CovidRestrictionPortStay = {
  allowed: Maybe<Scalars['Boolean']['output']>
  duration: Scalars['Float']['output']
}

export type CovidRestrictionPortStayInput = {
  allowed?: InputMaybe<Scalars['Boolean']['input']>
  duration: Scalars['Float']['input']
}

export enum CovidRestrictionSource {
  PortAgent = 'PortAgent',
}

export type CovidRestrictionTestingFacilities = {
  pcrTesting: CovidRestrictionTestingFacilitiesTesting
  rapidTesting: CovidRestrictionTestingFacilitiesTesting
}

export type CovidRestrictionTestingFacilitiesInput = {
  pcrTesting: CovidRestrictionTestingFacilitiesTestingInput
  rapidTesting: CovidRestrictionTestingFacilitiesTestingInput
}

export type CovidRestrictionTestingFacilitiesTesting = {
  available: Maybe<Scalars['Boolean']['output']>
  availableAfter: Scalars['Float']['output']
}

export type CovidRestrictionTestingFacilitiesTestingInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>
  availableAfter: Scalars['Float']['input']
}

export type CovidRestrictionVaccination = {
  mandatory: Maybe<Scalars['Boolean']['output']>
}

export type CovidRestrictionVaccinationInput = {
  mandatory?: InputMaybe<Scalars['Boolean']['input']>
}

export type CovidRestrictionsLegArrivalOrDepartureInput = {
  countryAlpha2?: InputMaybe<Scalars['String']['input']>
  iata: Scalars['String']['input']
}

export type CovidRestrictionsLegInput = {
  arrival: CovidRestrictionsLegArrivalOrDepartureInput
  departure: CovidRestrictionsLegArrivalOrDepartureInput
}

export type CovidTestRequirement = {
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  testWindow: Array<CovidTestWindow>
  title: Scalars['String']['output']
}

export type CovidTestWindow = {
  condition: Maybe<Scalars['String']['output']>
  medicalRequirementType: Maybe<Scalars['String']['output']>
  text: Scalars['String']['output']
  type: Scalars['String']['output']
  value: Scalars['Float']['output']
}

export type CovidTestingRequirement = {
  applicableTo: VaccinationStatus
  id: Scalars['UUID']['output']
  timeReference: TimeReference
  travelPurpose: TravelPurpose
  type: Array<Scalars['String']['output']>
}

export type CreateBookingFromPnrInput = {
  bucketId: Scalars['UUID']['input']
  comment?: InputMaybe<Scalars['String']['input']>
  currency: Currency
  fareAmountWithCommission: Scalars['Float']['input']
  fareType: FareType
  fbc?: InputMaybe<Scalars['String']['input']>
  freeBagAllowance?: InputMaybe<Scalars['Int']['input']>
  freeBagAllowanceType?: InputMaybe<FreeBagAllowanceType>
  freeBagAllowanceWeightUnit?: InputMaybe<FreeBagAllowanceWeightUnit>
  lastTicketingTime: Scalars['String']['input']
  pnr: Scalars['String']['input']
  seamanId: Scalars['UUID']['input']
}

export type CreateBookingFromScratchInput = {
  /** Baggage pieces allowance */
  baggagePieces?: InputMaybe<Scalars['Int']['input']>
  /** Baggage weight allowance in kilograms */
  baggageWeight?: InputMaybe<Scalars['Float']['input']>
  /** Bucket ID of the associated crew change */
  bucketId: Scalars['String']['input']
  /** Any comment related to the booking */
  comment?: InputMaybe<Scalars['String']['input']>
  /**
   * Total fare amount (incl taxes but not travel office commission), in integer format with 2 implied decimals
   * For example, if amount is USD 100.00, then this value should be 10000
   */
  fareAmountWithoutCommission: Scalars['Int']['input']
  /** Fare basis code */
  fareBasisCode?: InputMaybe<Scalars['String']['input']>
  /** Currency of the fareAmount */
  fareCurrency: Currency
  /** Type of fare */
  fareType: FareType
  /** Is the pnr Amadeus or not */
  isAmadeus?: InputMaybe<Scalars['Boolean']['input']>
  /** Last Ticketing Date */
  ltd?: InputMaybe<Scalars['DateTime']['input']>
  /** Passenger Name Record for the booking */
  pnr?: InputMaybe<Scalars['String']['input']>
  /** Refundability of the ticket */
  refundable?: InputMaybe<ParsedRefundInfo>
  /** ID of the seaman to create the booking for */
  seamanId: Scalars['String']['input']
  /** Flight segments parsed */
  segments?: InputMaybe<Array<ParsedSegmentInput>>
  /** Status of the added booking */
  status: FlightBookingStatus
  /** TicketLine of the booking */
  ticketLine?: InputMaybe<Scalars['String']['input']>
  /** Travel office to associate the booking to */
  travelOfficeConfigurationId: Scalars['String']['input']
  /** Validating carrier code */
  validatingCarrier?: InputMaybe<Scalars['String']['input']>
}

export type CreateCrewChangeInput = {
  crewChangeSeamanDetailIds: Array<Scalars['UUID']['input']>
  isLinkedToCrewList?: InputMaybe<Scalars['Boolean']['input']>
  scheduleId: Scalars['UUID']['input']
  seamen: Array<CreateCrewChangeSeamanInput>
}

export type CreateCrewChangeSeamanInput = {
  crewChangeSeamanSourceId?: InputMaybe<Scalars['String']['input']>
  role: CrewChangeRole
  seamanId: Scalars['UUID']['input']
}

export type CreateDemoCrewChangeInput = {
  ETA?: InputMaybe<Scalars['DateTime']['input']>
  ETB?: InputMaybe<Scalars['DateTime']['input']>
  ETD?: InputMaybe<Scalars['DateTime']['input']>
  numOffsigners: Scalars['Int']['input']
  numOnsigners: Scalars['Int']['input']
  preferredOffsignerNationalities: Array<Scalars['String']['input']>
  preferredOnsignerNationalities: Array<Scalars['String']['input']>
  seaportId: Scalars['UUID']['input']
  vesselId: Scalars['UUID']['input']
}

export type CreateTemporaryCrewChangeInput = {
  crewChangeSeamanDetailIds: Array<Scalars['UUID']['input']>
  seamen: Array<CreateCrewChangeSeamanInput>
  vesselId: Scalars['UUID']['input']
}

export type CrewChange = {
  archived: Scalars['Boolean']['output']
  bucketId: Scalars['UUID']['output']
  completed: Scalars['Boolean']['output']
  crewChangeComments: Array<CrewChangeComment>
  crewChangeCovidRestrictions: Array<CrewChangeCovidRestriction>
  crewChangeSeamanDetail: Maybe<Array<CrewChangeSeamanDetail>>
  id: Scalars['UUID']['output']
  nearestAirport: Airport
  portAgents: Array<CrewChangePortAgent>
  promotedSeamen: Array<CrewChangeSeaman>
  seamen: Array<CrewChangeSeaman>
  startOn: Scalars['DateTime']['output']
  started: Scalars['Boolean']['output']
  temporary: Scalars['Boolean']['output']
  vessel: Vessel
  vesselSchedule: VesselSchedule
}

export type CrewChangeComment = {
  comment: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  createdBy: SafePartialUser
  id: Scalars['UUID']['output']
}

export type CrewChangeCovidRestriction = {
  createdAt: Scalars['DateTime']['output']
  createdBy: SafePartialUser
  data: Scalars['JSONObject']['output']
  id: Scalars['UUID']['output']
  offSignersRestrictions: CovidRestrictionOffSignersRestrictions
  onSignersRestrictions: CovidRestrictionOnSignersRestrictions
  source: CovidRestrictionSource
  testingFacilities: CovidRestrictionTestingFacilities
  vaccination: CovidRestrictionVaccination
  version: Scalars['String']['output']
}

export type CrewChangeCovidRestrictionInput = {
  offSignersRestrictions: CovidRestrictionOffSignersRestrictionsInput
  onSignersRestrictions: CovidRestrictionOnSignersRestrictionsInput
  testingFacilities: CovidRestrictionTestingFacilitiesInput
  vaccination: CovidRestrictionVaccinationInput
  version: Scalars['String']['input']
}

export type CrewChangeDocument = {
  archived: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  documentName: Scalars['String']['output']
  documentType: DocumentType
  flightBookingId: Maybe<Scalars['UUID']['output']>
  id: Scalars['UUID']['output']
  seamanId: Maybe<Scalars['UUID']['output']>
  user: SafePartialUser
}

export type CrewChangeDocumentDownloadUrl = {
  documentId: Scalars['String']['output']
  presignedUrl: Scalars['String']['output']
}

export type CrewChangeDocumentEvent = {
  /** The new document added to the crew change */
  crewChangeDocument: Maybe<CrewChangeDocument>
  /** Whether the new document was successfully uploaded to S3 and captured in the database */
  uploadSuccess: Maybe<Scalars['Boolean']['output']>
}

export type CrewChangeInfo = {
  startEmail: StartCrewChangeEmail
}

export type CrewChangeInfoStartEmailArgs = {
  params?: InputMaybe<StartCrewChangeEmailParams>
}

export type CrewChangeLinkage = {
  bucketId: Maybe<Scalars['String']['output']>
  isLinked: Scalars['Boolean']['output']
  isTemporary: Scalars['Boolean']['output']
}

export type CrewChangeMutation = {
  cancelCrewChange: Maybe<Scalars['Void']['output']>
  notifyFlightsBooked: Maybe<Scalars['Void']['output']>
  reset: Maybe<Scalars['Void']['output']>
  restoreCrewChange: RestoreCrewChangeResult
  start: Maybe<Scalars['Void']['output']>
  syncSeamenFromSource: Maybe<Scalars['Void']['output']>
  updateStatus: Maybe<Scalars['Void']['output']>
}

export type CrewChangeMutationStartArgs = {
  params?: InputMaybe<StartCrewChangeParams>
}

export type CrewChangeMutationUpdateStatusArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>
  completed?: InputMaybe<Scalars['Boolean']['input']>
  started?: InputMaybe<Scalars['Boolean']['input']>
}

export type CrewChangeOverview = {
  ETA: Scalars['DateTime']['output']
  ETB: Scalars['DateTime']['output']
  ETD: Scalars['DateTime']['output']
  activeCrewOperatorType: ActiveCrewOperatorType
  activeCrewSupervisorType: ActiveCrewSupervisorType
  archived: Scalars['Boolean']['output']
  backupCrewOperatorId: Maybe<Scalars['UUID']['output']>
  backupCrewOperatorName: Maybe<Scalars['String']['output']>
  backupCrewSupervisorId: Maybe<Scalars['UUID']['output']>
  backupCrewSupervisorName: Maybe<Scalars['String']['output']>
  bookedFlightBookings: Scalars['Int']['output']
  bucketId: Scalars['UUID']['output']
  cancelled: Scalars['Boolean']['output']
  completed: Scalars['Boolean']['output']
  flightBookingsLTD: Maybe<Scalars['DateTime']['output']>
  isScheduleCancelled: Scalars['Boolean']['output']
  manningAgencies: Array<Scalars['UUID']['output']>
  numOffsigners: Scalars['Int']['output']
  numOnsigners: Scalars['Int']['output']
  openFlightBookings: Scalars['Int']['output']
  portAgents: Array<Scalars['UUID']['output']>
  portName: Maybe<Scalars['String']['output']>
  portTimezoneOlson: Maybe<Scalars['String']['output']>
  primaryCrewOperatorId: Maybe<Scalars['UUID']['output']>
  primaryCrewOperatorName: Maybe<Scalars['String']['output']>
  primaryCrewSupervisorId: Maybe<Scalars['UUID']['output']>
  primaryCrewSupervisorName: Maybe<Scalars['String']['output']>
  started: Maybe<Scalars['Boolean']['output']>
  ticketedFlightBookings: Scalars['Int']['output']
  totalNumSeafarersIncludingPromotions: Scalars['Int']['output']
  vesselId: Scalars['UUID']['output']
  vesselName: Scalars['String']['output']
  vesselScheduleSource: VesselScheduleSource
}

export type CrewChangePortAgent = {
  acceptedAt: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  portAgent: SafePartialUser
}

export enum CrewChangeRole {
  OffSigner = 'OffSigner',
  OnSigner = 'OnSigner',
}

export type CrewChangeSeaman = {
  active: Scalars['Boolean']['output']
  confirmationStatuses: Array<SeamanCrewChangeConfirmationStatus>
  createdAt: Scalars['DateTime']['output']
  crewChangeVisaRequirements: Array<CrewChangeVisaRequirement>
  crewHash: Maybe<Scalars['String']['output']>
  crewListEntry: CrewChange
  id: Scalars['UUID']['output']
  isSearchDeferred: Scalars['Boolean']['output']
  loadTs: Maybe<Scalars['Int']['output']>
  manningAgency: Maybe<ManningAgency>
  role: CrewChangeRole
  seaman: Seaman
  seamanId: Scalars['UUID']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type CrewChangeSeamanDetail = {
  contractEndAt: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  crewChange: CrewChange
  crewHash: Scalars['String']['output']
  disembarkationAt: Scalars['DateTime']['output']
  embarkationAt: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  loadTs: Maybe<Scalars['Int']['output']>
  manningAgency: Maybe<ManningAgency>
  role: CrewChangeRole
  seaman: Seaman
  seamanId: Scalars['UUID']['output']
  sourceId: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type CrewChangeSeamanForCreation = {
  contractEndAt: Scalars['DateTime']['output']
  crewChangeLinkage: CrewChangeLinkage
  crewHash: Scalars['String']['output']
  id: Scalars['UUID']['output']
  role: CrewChangeRole
  seaman: Seaman
  seamanId: Scalars['UUID']['output']
  signOffDate: Scalars['DateTime']['output']
  signOnDate: Scalars['DateTime']['output']
  sourceId: Maybe<Scalars['String']['output']>
  vessel: Maybe<Vessel>
}

export type CrewChangeSeamenConflict = {
  active: Scalars['Boolean']['output']
  conflictingCrewChanges: Array<Maybe<CrewChangeOverview>>
  crewHash: Scalars['String']['output']
  id: Scalars['UUID']['output']
  isSearchDeferred: Scalars['Boolean']['output']
  manningAgencyId: Maybe<Scalars['UUID']['output']>
  role: CrewChangeRole
  seaman: Seaman
  seamanId: Scalars['UUID']['output']
  sourceId: Maybe<Scalars['UUID']['output']>
  tenantId: Scalars['UUID']['output']
}

export type CrewChangeSeamenForRanksResponse = {
  crewChangeSeamen: Array<CrewChangeSeamanForCreation>
  rankId: Scalars['Int']['output']
}

export type CrewChangeState = {
  active: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  crewHash: Scalars['String']['output']
  id: Scalars['UUID']['output']
  seaportName: Scalars['String']['output']
  startOn: Scalars['DateTime']['output']
  updatedAt: Scalars['DateTime']['output']
  vessel: Maybe<Vessel>
}

export type CrewChangeVisaRequirement = {
  additionalComments: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  createdBy: SafePartialUser
  id: Scalars['UUID']['output']
  isVisaRequired: Scalars['Boolean']['output']
  region: Scalars['String']['output']
  source: VisaRequirementSource
  visaRequiredOn: VisaOrCovidTestRequiredOn
}

export type CrewChangesDataSync = {
  type: DataSyncType
  updatedAt: Scalars['DateTime']['output']
}

export type CrewListEntry = {
  comments: Array<CrewChangeComment>
  id: Scalars['UUID']['output']
}

export type CrewListEntryMutation = {
  addComment: CrewListEntry
  addCovidRectriction: CrewListEntry
  addVisaRequirements: CrewListEntry
}

export type CrewListEntryMutationAddCommentArgs = {
  comment: Scalars['String']['input']
}

export type CrewListEntryMutationAddCovidRectrictionArgs = {
  restriction: CrewChangeCovidRestrictionInput
}

export type CrewListEntryMutationAddVisaRequirementsArgs = {
  requirements: Array<AddCrewChangeVisaRequirementsInput>
}

export enum Currency {
  Eur = 'EUR',
  Php = 'PHP',
  Usd = 'USD',
}

export type CurrencyRate = {
  EUR: Scalars['Float']['output']
  PHP: Scalars['Float']['output']
  USD: Scalars['Float']['output']
}

export enum DataSourceSystem {
  CrewService = 'CrewService',
  CrewStar = 'CrewStar',
  Hanseatic = 'Hanseatic',
  Manual = 'Manual',
  Mintra = 'Mintra',
  Seacrew = 'Seacrew',
  Stolt = 'Stolt',
}

export enum DataSourceSystemType {
  External = 'External',
  Internal = 'Internal',
}

export enum DataSyncAction {
  Crawl = 'Crawl',
  Integrate = 'Integrate',
  UpdateSeamen = 'UpdateSeamen',
}

export type DataSyncLog = {
  action: Maybe<DataSyncAction>
  appVersion: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  status: Maybe<DataSyncStatus>
  tenantId: Scalars['UUID']['output']
  updatedAt: Scalars['DateTime']['output']
  user: Maybe<User>
  userId: Scalars['UUID']['output']
}

export enum DataSyncStatus {
  Completed = 'Completed',
  Failed = 'Failed',
  InProgress = 'InProgress',
}

export enum DataSyncType {
  Automatic = 'Automatic',
  Manual = 'Manual',
}

export type DeferFlightSearchParams = {
  /** Bucket id for the crew change the seafarer belongs to */
  bucketId: Scalars['UUID']['input']
  /** Any custom comment from the user */
  comment: Scalars['String']['input']
  /** Crew change seamen for whom the request is being performed */
  crewChangeSeamanId: Scalars['UUID']['input']
  /** The travel agent who will receive the deferral request */
  travelAgent: DeferFlightTravelAgent
}

export type DeferFlightTravelAgent = {
  email: Scalars['String']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
}

export type DeferFlightsParams = {
  bookingIds: Array<Scalars['UUID']['input']>
  bucketId: Scalars['UUID']['input']
  comment?: InputMaybe<Scalars['String']['input']>
}

export type DeferralList = {
  count: Scalars['Int']['output']
  items: Array<DeferralOverview>
}

/** A representation of a particular deferral (either search, booking, ticketing, or cancellation) */
export type DeferralOverview = {
  /** Bucket ID of the corresponding crew change */
  bucketId: Scalars['UUID']['output']
  /** The crew change seaman that is linked to the deferral */
  crewChangeSeaman: CrewChangeSeaman
  flightBooking: Maybe<FlightBooking>
  /** Required flight route for the deferral */
  flightRoute: FlightRoute
  /** Name of the port the vessel is associated with for this particular seaman */
  portName: Maybe<Scalars['String']['output']>
  /** Name of the vessel the deferred seaman belongs to */
  vesselName: Scalars['String']['output']
}

export enum DeferralReversionType {
  Booking = 'booking',
  Ticketing = 'ticketing',
}

/** A summary of pending and critical deferrals */
export type DeferralSummary = {
  /**
   * Total number of pending deferrals that are critical (departure within the next 3 days)
   * that have not been actioned by the travel team
   */
  critical: Scalars['Int']['output']
  /** Total number of pending deferrals that have not been actioned by the travel team */
  pending: Scalars['Int']['output']
}

export enum DeferralType {
  Booking = 'Booking',
  Cancellation = 'Cancellation',
  Search = 'Search',
  Ticketing = 'Ticketing',
}

/** A summary of pending deferrals for all different deferral types */
export type DeferralsOverviewSummary = {
  cancellationDeferrals: DeferralSummary
  reservationDeferrals: DeferralSummary
  searchDeferrals: Scalars['Int']['output']
  ticketingDeferrals: DeferralSummary
}

export type DetectFlightItineraryChangesParams = {
  bookingId: Scalars['UUID']['input']
}

export enum DocumentType {
  CrewList = 'CrewList',
  ETicket = 'ETicket',
  FitForTravel = 'FitForTravel',
  LetterOfGuarantee = 'LetterOfGuarantee',
  LetterOfInvitation = 'LetterOfInvitation',
  Miscellaneous = 'Miscellaneous',
  Oec = 'Oec',
  Passport = 'Passport',
  PcrTestResult = 'PcrTestResult',
  SeamanBook = 'SeamanBook',
}

export enum DocumentsAvailableStatus {
  All = 'All',
  None = 'None',
  Some = 'Some',
}

export type ETicketCoupon = {
  amount: Maybe<Scalars['String']['output']>
  couponNumber: Scalars['String']['output']
  couponStatus: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  currency: Maybe<Scalars['String']['output']>
  fareBasisCode: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  updatedAt: Scalars['DateTime']['output']
}

export enum ETicketEmailAttachmentFormat {
  Pdf = 'Pdf',
  Zip = 'Zip',
}

export type ETicketRecord = {
  ticket: FlightBookingDocument
}

export type EditBookingFromScratchInput = {
  /** Baggage pieces allowance */
  baggagePieces?: InputMaybe<Scalars['Int']['input']>
  /** Baggage weight allowance in kilograms */
  baggageWeight?: InputMaybe<Scalars['Float']['input']>
  /** Bucket ID of the associated crew change */
  bucketId: Scalars['String']['input']
  /** Any comment related to the booking */
  comment?: InputMaybe<Scalars['String']['input']>
  /**
   * Total fare amount (incl taxes but not travel agent commission), in integer format with 2 implied decimals
   * For example, if amount is USD 100.00, then this value should be 10000
   */
  fareAmountWithoutCommission: Scalars['Int']['input']
  /** Fare basis code */
  fareBasisCode?: InputMaybe<Scalars['String']['input']>
  /** Currency of the fareAmount */
  fareCurrency: Currency
  /** Type of fare */
  fareType: FareType
  /** ID of the booking to edit */
  id: Scalars['UUID']['input']
  /** Is the pnr Amadeus or not */
  isAmadeus?: InputMaybe<Scalars['Boolean']['input']>
  /** Last Ticketing Date */
  ltd?: InputMaybe<Scalars['DateTime']['input']>
  /** Passenger Name Record for the booking */
  pnr?: InputMaybe<Scalars['String']['input']>
  /** Refundability of the ticket */
  refundable?: InputMaybe<ParsedRefundInfo>
  /** ID of the seaman to create the booking for */
  seamanId: Scalars['String']['input']
  /** Flight segments parsed */
  segments?: InputMaybe<Array<ParsedSegmentInput>>
  /** Status of the added booking */
  status: FlightBookingStatus
  /** TicketLine of the booking */
  ticketLine?: InputMaybe<Scalars['String']['input']>
  /** Travel office to associate the booking to */
  travelOfficeConfigurationId: Scalars['String']['input']
  /** Validating carrier code */
  validatingCarrier?: InputMaybe<Scalars['String']['input']>
}

export type EditPnrInput = {
  bookingId: Scalars['UUID']['input']
  comment?: InputMaybe<Scalars['String']['input']>
  currency: Currency
  fareAmountWithCommission: Scalars['Float']['input']
  fareType: FareType
  fbc?: InputMaybe<Scalars['String']['input']>
  freeBagAllowance?: InputMaybe<Scalars['Int']['input']>
  freeBagAllowanceType?: InputMaybe<FreeBagAllowanceType>
  freeBagAllowanceWeightUnit?: InputMaybe<FreeBagAllowanceWeightUnit>
  lastTicketingTime: Scalars['String']['input']
  pnr: Scalars['String']['input']
}

export type EditPortPlanningScenarioInput = {
  ETA: Scalars['DateTime']['input']
  ETB: Scalars['DateTime']['input']
  ETD: Scalars['DateTime']['input']
  scenarioId: Scalars['UUID']['input']
  seaportId?: InputMaybe<Scalars['UUID']['input']>
}

export type EmailAttachment = {
  content: Scalars['String']['input']
  disposition?: InputMaybe<Scalars['String']['input']>
  filename: Scalars['String']['input']
  type?: InputMaybe<Scalars['String']['input']>
}

export type EmailAttachmentOptions = {
  eTicketFormat?: InputMaybe<ETicketEmailAttachmentFormat>
  includeFlightDocuments: Scalars['Boolean']['input']
  includeSeamanBooks: Scalars['Boolean']['input']
  includeSeamanPassports: Scalars['Boolean']['input']
  includeVisas: Scalars['Boolean']['input']
  seamenRole?: InputMaybe<CrewChangeRole>
}

export type EvaluateScenarioInput = {
  crewChangeSeamanDetailIds: Array<Scalars['UUID']['input']>
  scenarioIds: Array<Scalars['UUID']['input']>
}

export enum FareType {
  AtpcoNegoCorporateFares = 'ATPCONegoCorporateFares',
  AtpcoNegoFares = 'ATPCONegoFares',
  AtpcoPrivateFares = 'ATPCOPrivateFares',
  AmadeusNegoFares = 'AmadeusNegoFares',
  ElectronicTicket = 'ElectronicTicket',
  PublicFares = 'PublicFares',
  RefundableFares = 'RefundableFares',
  UniFares = 'UniFares',
}

/**
 * Flight which wasn't persisted to the DB.
 * It contains only data returned from search engine, but kept as close to DB version as possible
 * NOTE: id of that flight can be one saved to DB eventually or one which will not if same flight already exists in DB.
 * flightHash + travelOfficeConfigurationId is main fields to refer that flight on BE side
 *
 * used for search optimisation
 */
export type FastFlight = {
  amadeusSessionId: Scalars['String']['output']
  flightHash: Scalars['String']['output']
  id: Scalars['UUID']['output']
  majorityCarrier: Maybe<Scalars['String']['output']>
  offer: FastFlightOffer
  segments: Array<FastFlightSegment>
  tenantId: Scalars['String']['output']
  travelOfficeConfiguration: TravelOfficeConfiguration
  travelOfficeConfigurationId: Scalars['String']['output']
  validatingCarrier: Maybe<Scalars['String']['output']>
}

/**
 * This is input version of FastFlight type to pass it back from FE which can be persisted as Flight
 * it is needed as FastFlight is something that not stored in DB yet.
 */
export type FastFlightInput = {
  amadeusSessionId: Scalars['String']['input']
  flightHash: Scalars['String']['input']
  id: Scalars['UUID']['input']
  majorityCarrier?: InputMaybe<Scalars['String']['input']>
  offer: FastFlightOfferInput
  segments: Array<FastFlightSegmentInput>
  travelOfficeConfigurationId: Scalars['String']['input']
  validatingCarrier?: InputMaybe<Scalars['String']['input']>
}

export type FastFlightOffer = {
  amadeusSessionId: Scalars['String']['output']
  amount: Scalars['Float']['output']
  currency: Scalars['String']['output']
  id: Scalars['UUID']['output']
  minirules: Maybe<FlightFareMinirules>
  passengerType: Maybe<PassengerType>
  rules: Array<FastFlightOfferRules>
  segmentFares: Array<FastFlightSegmentFare>
  tax: Maybe<Scalars['Float']['output']>
}

export type FastFlightOfferInput = {
  amadeusSessionId: Scalars['String']['input']
  amount: Scalars['Float']['input']
  currency: Scalars['String']['input']
  id: Scalars['UUID']['input']
  minirules?: InputMaybe<FlightFareMinirulesInput>
  passengerType?: InputMaybe<PassengerType>
  rules: Array<FastFlightOfferRulesInput>
  segmentFares: Array<FlightSegmentFareInput>
  tax?: InputMaybe<Scalars['Float']['input']>
}

export type FastFlightOfferRules = {
  id: Scalars['UUID']['output']
  ruleType: FlightOfferRuleType
  rules: Array<Scalars['String']['output']>
}

export type FastFlightOfferRulesInput = {
  id: Scalars['UUID']['input']
  ruleType: FlightOfferRuleType
  rules: Array<Scalars['String']['input']>
}

export type FastFlightSegment = {
  arrival: FlightSegmentDepOrArrival
  departure: FlightSegmentDepOrArrival
  flightNumber: Scalars['Int']['output']
  id: Scalars['UUID']['output']
  index: Scalars['Int']['output']
  marketingCarrier: Maybe<Airline>
  operatingCarrier: Maybe<Airline>
}

export type FastFlightSegmentDepOrArrivalInput = {
  city?: InputMaybe<Scalars['String']['input']>
  countryAlpha2?: InputMaybe<Scalars['String']['input']>
  date: Scalars['String']['input']
  iata: Scalars['String']['input']
  name?: InputMaybe<Scalars['String']['input']>
  terminal?: InputMaybe<Scalars['String']['input']>
  time: Scalars['String']['input']
  timezoneOlson?: InputMaybe<Scalars['String']['input']>
}

export type FastFlightSegmentFare = {
  availableSeats: Maybe<Scalars['Int']['output']>
  baggageDetails: BaggageDetails
  bookingClass: Maybe<Scalars['String']['output']>
  fareBasisCode: Scalars['String']['output']
  fareTypes: Array<FareType>
  flightSegmentId: Scalars['UUID']['output']
}

export type FastFlightSegmentInput = {
  arrival: FastFlightSegmentDepOrArrivalInput
  departure: FastFlightSegmentDepOrArrivalInput
  flightNumber: Scalars['Int']['input']
  id: Scalars['UUID']['input']
  index: Scalars['Int']['input']
  marketingCarrier: AirlineInput
  operatingCarrier?: InputMaybe<AirlineInput>
}

export type FastFlightSelectParams = {
  bookingCreationSource?: InputMaybe<FlightBookingCreationSource>
  bucketId: Scalars['UUID']['input']
  flightHash: Scalars['String']['input']
  offerId: Scalars['UUID']['input']
  replaceBookingId?: InputMaybe<Scalars['UUID']['input']>
  seamanId: Scalars['UUID']['input']
  splitTicketingParams?: InputMaybe<SelectFlightSplitTicketingParams>
  travelOfficeConfigurationId: Scalars['UUID']['input']
}

export type FinalFlightOffer = {
  amount: Scalars['Int']['output']
  currency: Currency
  fixedCommission: Scalars['Int']['output']
  fixedCommissionCurrency: Currency
  percentageCommission: Scalars['Float']['output']
}

export type Flight = {
  createdAt: Scalars['DateTime']['output']
  flightHash: Scalars['String']['output']
  id: Scalars['UUID']['output']
  majorityCarrier: Maybe<Scalars['String']['output']>
  offers: Array<FlightOffer>
  priceAndFareOffer: PriceAndFareOfferResult
  priceAndFareOfferWithAlternativeOffers: Array<PriceAndFareOfferResult>
  segments: Array<FlightSegment>
  travelOfficeConfiguration: TravelOfficeConfiguration
  travelOfficeConfigurationId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  validatingCarrier: Maybe<Scalars['String']['output']>
}

export type FlightBooking = {
  active: Scalars['Boolean']['output']
  amadeusSessionId: Maybe<Scalars['String']['output']>
  autoTicket: Scalars['Boolean']['output']
  bookingWarnings: Array<Scalars['String']['output']>
  bucketId: Maybe<Scalars['String']['output']>
  comment: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  finalOffer: Maybe<FinalFlightOffer>
  flight: Flight
  id: Scalars['UUID']['output']
  isAmadeus: Scalars['Boolean']['output']
  lastPricedAt: Maybe<Scalars['DateTime']['output']>
  lastTicketingTime: Maybe<Scalars['DateTime']['output']>
  lastTicketingTimeSource: LastTicketingTimeSource
  managedByTravelTeam: Scalars['Boolean']['output']
  monitored: Scalars['Boolean']['output']
  offer: FlightOffer
  pendingUpdatedByTravelAgentNotification: Scalars['Boolean']['output']
  pnr: Maybe<Scalars['String']['output']>
  seaman: Seaman
  splitTicketingInfo: Maybe<SplitTicketingInfo>
  status: FlightBookingStatus
  switchForIdenticalCheaperOfferAutomatically: Scalars['Boolean']['output']
  switchForSimilarCheaperOfferAutomatically: Scalars['Boolean']['output']
  ticketLine: Maybe<Scalars['String']['output']>
  ticketLineData: Maybe<FlightTicketLineData>
  ticketedAt: Maybe<Scalars['DateTime']['output']>
  travelOfficeConfigurationId: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export enum FlightBookingCreationSource {
  FlightCopy = 'FlightCopy',
  MonitoringRecommendation = 'MonitoringRecommendation',
  Search = 'Search',
  SearchWithAlternativeFares = 'SearchWithAlternativeFares',
}

export type FlightBookingDocument = {
  createdAt: Scalars['DateTime']['output']
  currency: Scalars['String']['output']
  documentNumber: Scalars['String']['output']
  eTicketCoupons: Array<ETicketCoupon>
  id: Scalars['UUID']['output']
  passengerFirstName: Scalars['String']['output']
  passengerLastName: Scalars['String']['output']
  totalAmount: Scalars['String']['output']
  type: FlightBookingDocumentType
  updatedAt: Scalars['DateTime']['output']
}

export enum FlightBookingDocumentType {
  Emda = 'EMDA',
  Emds = 'EMDS',
  ETicket = 'ETicket',
}

export type FlightBookingMutation = {
  cancelETicket: FlightBooking
  cancelPnrItinerary: FlightBooking
  deactivate: FlightBooking
  deleteAllTsts: Maybe<Scalars['Void']['output']>
  dismissCheaperOffers: Maybe<Scalars['Void']['output']>
  fetchETicket: ETicketRecord
  id: Scalars['UUID']['output']
  initRefund: FlightBookingRefund
  issueTicket: FlightBooking
  searchForCheaperOffers: Maybe<Array<SearchForCheaperOfferResult>>
  setBookingMonitoringStatus: FlightBooking
}

export type FlightBookingMutationSetBookingMonitoringStatusArgs = {
  monitored: Scalars['Boolean']['input']
  switchForIdenticalCheaperOfferAutomatically?: InputMaybe<Scalars['Boolean']['input']>
  switchForSimilarCheaperOfferAutomatically?: InputMaybe<Scalars['Boolean']['input']>
}

export type FlightBookingRefund = {
  amount: Maybe<Scalars['Int']['output']>
  currencyCode: Maybe<Scalars['String']['output']>
  decimalPlaces: Maybe<Scalars['String']['output']>
  flightBooking: FlightBooking
  id: Scalars['UUID']['output']
  penalties: Array<FlightBookingRefundPenalty>
  status: FlightBookingRefundStatus
  taxes: Array<Maybe<FlightBookingRefundTax>>
}

export type FlightBookingRefundMutation = {
  ignore: FlightBookingRefund
  process: FlightBookingRefund
}

export type FlightBookingRefundPenalty = {
  amount: Scalars['Int']['output']
  currencyCode: Maybe<Scalars['String']['output']>
  decimalPlaces: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  penaltyType: Maybe<Scalars['String']['output']>
  percentOrAmount: Maybe<PenaltyCalculationType>
}

export enum FlightBookingRefundStatus {
  Ignored = 'Ignored',
  Initiated = 'Initiated',
  Processed = 'Processed',
  Unrefundable = 'Unrefundable',
}

export type FlightBookingRefundTax = {
  amount: Scalars['Int']['output']
  category: Scalars['String']['output']
  currencyCode: Scalars['String']['output']
  decimalPlaces: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  taxIsoCode: Scalars['String']['output']
}

export enum FlightBookingStatus {
  Booked = 'Booked',
  BookingDeferralCancelled = 'BookingDeferralCancelled',
  BookingDeferred = 'BookingDeferred',
  BookingFailed = 'BookingFailed',
  CancellationDeferred = 'CancellationDeferred',
  Cancelled = 'Cancelled',
  PnrNotFound = 'PNRNotFound',
  Rebooked = 'Rebooked',
  RebookingDeferred = 'RebookingDeferred',
  Refunded = 'Refunded',
  Selected = 'Selected',
  Ticketed = 'Ticketed',
  TicketingDeferralCancelled = 'TicketingDeferralCancelled',
  TicketingDeferred = 'TicketingDeferred',
  TicketingFailed = 'TicketingFailed',
}

export type FlightBookingSummary = {
  crewChange: CrewChangeOverview
  eTicketDownloadUrl: Maybe<Scalars['String']['output']>
  flight: Flight
  id: Scalars['String']['output']
  pnr: Scalars['String']['output']
  status: FlightBookingStatus
}

export type FlightBookingsMutation = {
  book: Array<FlightBooking>
  fetchETickets: Array<ETicketRecord>
  ids: Array<Scalars['UUID']['output']>
  setAutoTicketing: Array<FlightBooking>
}

export type FlightBookingsMutationSetAutoTicketingArgs = {
  autoTicket: Scalars['Boolean']['input']
}

export type FlightFareMinirules = {
  afterDeparture: Maybe<FlightFareMinirulesSet>
  afterDepartureNoShow: Maybe<FlightFareMinirulesSet>
  beforeDeparture: Maybe<FlightFareMinirulesSet>
  beforeDepartureNoShow: Maybe<FlightFareMinirulesSet>
}

export type FlightFareMinirulesInput = {
  afterDeparture?: InputMaybe<FlightFareMinirulesSetInput>
  afterDepartureNoShow?: InputMaybe<FlightFareMinirulesSetInput>
  beforeDeparture?: InputMaybe<FlightFareMinirulesSetInput>
  beforeDepartureNoShow?: InputMaybe<FlightFareMinirulesSetInput>
}

export type FlightFareMinirulesSet = {
  changeAllowed: Maybe<Scalars['Boolean']['output']>
  changeMaxPenaltyAmount: Maybe<Scalars['Float']['output']>
  isoTicketValidityDate: Maybe<Scalars['String']['output']>
  refundAllowed: Maybe<Scalars['Boolean']['output']>
  refundMaxPenaltyAmount: Maybe<Scalars['Float']['output']>
  revalidationAllowed: Maybe<Scalars['Boolean']['output']>
  revalidationMaxPenaltyAmount: Maybe<Scalars['Float']['output']>
  taxInformation: Maybe<FlightFareMinirulesTaxInformation>
}

export type FlightFareMinirulesSetInput = {
  changeAllowed?: InputMaybe<Scalars['Boolean']['input']>
  changeMaxPenaltyAmount?: InputMaybe<Scalars['Float']['input']>
  isoTicketValidityDate?: InputMaybe<Scalars['String']['input']>
  refundAllowed?: InputMaybe<Scalars['Boolean']['input']>
  refundMaxPenaltyAmount?: InputMaybe<Scalars['Float']['input']>
  revalidationAllowed?: InputMaybe<Scalars['Boolean']['input']>
  revalidationMaxPenaltyAmount?: InputMaybe<Scalars['Float']['input']>
  taxInformation?: InputMaybe<FlightFareMinirulesTaxInformationInput>
}

export type FlightFareMinirulesTaxInformation = {
  areYqYrTaxesNonRefundable: Maybe<Scalars['Boolean']['output']>
}

export type FlightFareMinirulesTaxInformationInput = {
  areYqYrTaxesNonRefundable?: InputMaybe<Scalars['Boolean']['input']>
}

export type FlightOffer = {
  amadeusSessionId: Maybe<Scalars['String']['output']>
  amount: Scalars['Float']['output']
  createdAt: Scalars['DateTime']['output']
  currency: Scalars['String']['output']
  flight: Flight
  flightId: Scalars['UUID']['output']
  id: Scalars['UUID']['output']
  minirules: Maybe<FlightFareMinirules>
  passengerType: Maybe<PassengerType>
  rules: Array<FlightOfferRules>
  segmentFares: Array<FlightSegmentFare>
  tax: Maybe<Scalars['Float']['output']>
  taxDetails: Maybe<Array<TaxDetail>>
  updatedAt: Scalars['DateTime']['output']
}

export type FlightOfferNotification = {
  active: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  flightOffer: FlightOffer
  id: Scalars['UUID']['output']
  updatedAt: Scalars['DateTime']['output']
  userSwitchedAt: Maybe<Scalars['DateTime']['output']>
}

export enum FlightOfferRefundType {
  NonRefundable = 'NonRefundable',
  Refundable = 'Refundable',
  RefundableExceptYqYr = 'RefundableExceptYqYr',
  RefundableWithPenalty = 'RefundableWithPenalty',
}

export enum FlightOfferRuleType {
  AdvancePurchase = 'AdvancePurchase',
  Miscellaneous = 'Miscellaneous',
  VoluntaryChanges = 'VoluntaryChanges',
  VoluntaryRefunds = 'VoluntaryRefunds',
}

export type FlightOfferRules = {
  createdAt: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  ruleType: FlightOfferRuleType
  rules: Array<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type FlightRecommendation = {
  active: Scalars['Boolean']['output']
  amadeusSessionId: Maybe<Scalars['String']['output']>
  bucketId: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  flight: Flight
  id: Scalars['UUID']['output']
  offer: FlightOffer
  planned: Scalars['String']['output']
  scheduleId: Scalars['String']['output']
  seaman: Seaman
  status: FlightBookingStatus
  tenant: Tenant
  updatedAt: Scalars['DateTime']['output']
}

export type FlightRecommendationInput = {
  /** When a crew change already exists, then the bucketId for that crew change to fetch seamen from that crew change */
  bucketId?: InputMaybe<Scalars['UUID']['input']>
  /** When a crew change doesn't exist, then crewChangeSeamanDetailIds for the seamen we need to consider */
  crewChangeSeamanDetailIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  crewChangeSeamenAirports: Array<FlightRecommendationSeamenAirportsInput>
  planned: Scalars['Boolean']['input']
  scheduleId: Scalars['String']['input']
  seaportAirportIatas: Array<Scalars['String']['input']>
  splitTicketingRecommendationsOnly?: InputMaybe<Scalars['Boolean']['input']>
}

export type FlightRecommendationResult = {
  flights: Maybe<Array<FlightRecommendation>>
  result: FlightRecommendationResultType
  scheduleId: Scalars['String']['output']
  seamanId: Scalars['String']['output']
}

export enum FlightRecommendationResultType {
  AirportNotSelected = 'AirportNotSelected',
  FlightNotRequired = 'FlightNotRequired',
  FlightRecommendation = 'FlightRecommendation',
  RecommendationNotFound = 'RecommendationNotFound',
}

export type FlightRecommendationSeamenAirportsInput = {
  airportIatas: Array<Scalars['String']['input']>
  crewChangeSeamanId: Scalars['UUID']['input']
}

export type FlightRoute = {
  arrival: Maybe<Scalars['String']['output']>
  departure: Maybe<Scalars['String']['output']>
}

export type FlightSearchItineraryParams = {
  /** Array of IATAs of possible arrival airports */
  arrivals: Array<Scalars['String']['input']>
  /** Date of arrival/departure (depending on timeSpecifier). Format YYYY-MM-DD */
  date: Scalars['String']['input']
  /** Number of days in date range search */
  daysInterval?: InputMaybe<Scalars['Int']['input']>
  /** Array of IATAs of possible departure airports */
  departures: Array<Scalars['String']['input']>
  /** IATA codes of the connecting points that should be excluded from the itinerary */
  excludedConnectingPoints?: InputMaybe<Array<Scalars['String']['input']>>
  /** Flight types to return for requested itinerary */
  flightTypes?: InputMaybe<Array<FlightType>>
  /** IATA codes of the connecting points that should be included in the itinerary */
  includedConnectingPoints?: InputMaybe<Array<Scalars['String']['input']>>
  /** Specific number of connections to be returned for each flight (no more and no less) */
  numberOfConnections?: InputMaybe<Scalars['Int']['input']>
  /** Time of arrival/departure (depending on timeSpecifier). Format HH:MM */
  time?: InputMaybe<Scalars['String']['input']>
  /** Specifies whether date and time are for arrival or departure */
  timeSpecifier: TimeSpecifier
}

export type FlightSearchParams = {
  /** Ignores travel office settings */
  disableBiasedPreference?: InputMaybe<Scalars['Boolean']['input']>
  /**
   * Exclude recommendations from these carriers
   * Might result in errors when combined with mandatoryCarriers, unless YY carrier is specified here
   * If not specified, Tilla travel office settings (disallowedAirlines) will be used
   */
  excludedCarriers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Only PublicFares and/or UniFares values accepted */
  fareTypes?: InputMaybe<Array<FareType>>
  /** Returns results regardless of availability status */
  ignoreAvailability?: InputMaybe<Scalars['Boolean']['input']>
  /** Params applied to the itinerary of the flight */
  itineraryParams: FlightSearchItineraryParams
  /**
   * Return ONLY recommendations from these carriers.
   * Might result in errors when combined with excludedCarriers, unless YY carrier is specified there
   */
  mandatoryCarriers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Maximum layover time for any given set of consecutive segments */
  maximumLayoverInMinutes?: InputMaybe<Scalars['Int']['input']>
  /** Minimum layover time for any given set of consecutive segments */
  minimumLayoverInMinutes?: InputMaybe<Scalars['Int']['input']>
  /** Number of flights to return per travel office. */
  numberOfFlights: Scalars['Int']['input']
  /** Return only recommendations built with flights marketed by different carriers */
  returnInterlineTravelSolutionsOnly?: InputMaybe<Scalars['Boolean']['input']>
  /** Return online travel solutions only for all requested segments */
  returnOnlineTravelSolutionsOnly?: InputMaybe<Scalars['Boolean']['input']>
  /** Searches for split tickets only, using Amadeus Virtual Interlining */
  searchForVirtualInterlining?: InputMaybe<Scalars['Boolean']['input']>
  /**
   * Bypasses tenant travel offices and performs search with specified travel office.
   * Only available to users with backoffice role.
   */
  travelOfficeConfigurationId?: InputMaybe<Scalars['UUID']['input']>
}

export type FlightSearchRecommendation = {
  flights: Array<FastFlight>
  recommendationId: Scalars['String']['output']
}

export type FlightSearchResult = {
  bestRecommendationId: Maybe<Scalars['UUID']['output']>
  recommendations: Array<FlightSearchRecommendation>
}

export type FlightSearchTimeRange = {
  /** Time range end in minutes from midnight */
  end: Scalars['Int']['input']
  /** Time range start in minutes from midnight */
  start: Scalars['Int']['input']
}

export type FlightSegment = {
  arrival: FlightSegmentDepOrArrival
  departure: FlightSegmentDepOrArrival
  flightNumber: Scalars['Int']['output']
  id: Scalars['UUID']['output']
  index: Scalars['Int']['output']
  marketingCarrier: Maybe<Airline>
  operatingCarrier: Maybe<Airline>
}

export type FlightSegmentChange = {
  active: Scalars['Boolean']['output']
  arrival: Maybe<FlightSegmentChangeDepOrArrival>
  bucketId: Scalars['UUID']['output']
  changeType: FlightSegmentChangeType
  departure: Maybe<FlightSegmentChangeDepOrArrival>
  flightBookingId: Scalars['UUID']['output']
  flightNumber: Maybe<Scalars['Int']['output']>
  flightSegment: FlightSegment
  id: Scalars['UUID']['output']
  marketingCarrier: Maybe<Airline>
  operatingCarrier: Maybe<Airline>
  seaman: Seaman
}

export type FlightSegmentChangeDepOrArrival = {
  city: Maybe<Scalars['String']['output']>
  countryAlpha2: Maybe<Scalars['String']['output']>
  date: Maybe<Scalars['String']['output']>
  iata: Maybe<Scalars['String']['output']>
  name: Maybe<Scalars['String']['output']>
  terminal: Maybe<Scalars['String']['output']>
  time: Maybe<Scalars['String']['output']>
  timezoneOlson: Maybe<Scalars['String']['output']>
}

export enum FlightSegmentChangeType {
  Added = 'Added',
  Cancelled = 'Cancelled',
  Flown = 'Flown',
  Removed = 'Removed',
  Rerouted = 'Rerouted',
  Rescheduled = 'Rescheduled',
}

export type FlightSegmentDepOrArrival = {
  city: Maybe<Scalars['String']['output']>
  countryAlpha2: Maybe<Scalars['String']['output']>
  date: Scalars['String']['output']
  iata: Scalars['String']['output']
  name: Maybe<Scalars['String']['output']>
  terminal: Maybe<Scalars['String']['output']>
  time: Scalars['String']['output']
  timezoneOlson: Maybe<Scalars['String']['output']>
}

export type FlightSegmentFare = {
  availableSeats: Maybe<Scalars['Int']['output']>
  baggageDetails: BaggageDetails
  bookingClass: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  fareBasisCode: Scalars['String']['output']
  fareTypes: Array<FareType>
  flightSegmentId: Scalars['String']['output']
  id: Scalars['UUID']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type FlightSegmentFareInput = {
  availableSeats?: InputMaybe<Scalars['Int']['input']>
  baggageDetails: BaggageDetailsInput
  bookingClass?: InputMaybe<Scalars['String']['input']>
  fareBasisCode: Scalars['String']['input']
  fareTypes: Array<FareType>
  flightSegmentId: Scalars['String']['input']
}

export enum FlightSegmentStatus {
  Confirmed = 'Confirmed',
  Flown = 'Flown',
  HoldingCancelled = 'HoldingCancelled',
  NoActionTaken = 'NoActionTaken',
  RemovedFromPnr = 'RemovedFromPnr',
  ScheduleChanged = 'ScheduleChanged',
  UnableToAcceptForSale = 'UnableToAcceptForSale',
  UnableToConfirm = 'UnableToConfirm',
}

export type FlightSelectParams = {
  bookingCreationSource?: InputMaybe<FlightBookingCreationSource>
  bucketId: Scalars['UUID']['input']
  flightId: Scalars['UUID']['input']
  offerId: Scalars['UUID']['input']
  replaceBookingId?: InputMaybe<Scalars['UUID']['input']>
  seamanId: Scalars['UUID']['input']
}

export type FlightTicketLineData = {
  carrier: Scalars['String']['output']
  currency: Maybe<Scalars['String']['output']>
  date: Maybe<Scalars['String']['output']>
  documentNumber: Scalars['String']['output']
  iataNumber: Scalars['String']['output']
  officeId: Scalars['String']['output']
  price: Maybe<Scalars['Float']['output']>
}

export enum FlightType {
  /** Flights with intermediate stops, and a change of flight number and aircraft */
  Connecting = 'Connecting',
  /** Flights with intermediate stops, and the same flight number and aircraft */
  Direct = 'Direct',
  /** Flights with no intermediate stops, and the same flight number and aircraft */
  NonStop = 'NonStop',
}

export enum FreeBagAllowanceType {
  NumberOfPieces = 'NumberOfPieces',
  Weight = 'Weight',
}

export enum FreeBagAllowanceWeightUnit {
  Kilogram = 'Kilogram',
  Pound = 'Pound',
}

export type GetShareFlightsEmailOptions = {
  includeFareInfo?: InputMaybe<Scalars['Boolean']['input']>
  includedSeafarers?: InputMaybe<IncludedSeafarersInEmails>
}

export enum IncludedSeafarersInEmails {
  All = 'All',
  Offsigners = 'Offsigners',
  Onsigners = 'Onsigners',
}

export type InvitePortAgentInput = {
  bucketId: Scalars['UUID']['input']
  email: Scalars['String']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
}

export enum LandingCrewChangeSeamanType {
  OffSigner = 'OffSigner',
  OnSigner = 'OnSigner',
}

export type LandingPnrSummary = {
  flightBooking: FlightBooking
  vesselSchedule: VesselSchedule
}

export type LandingPageVesselDetail = {
  schedules: Array<VesselSchedule>
  vessel: Vessel
  viewCrewChanges: Array<ViewUpdateCrewChanges>
  viewSeamanCrewChanges: Array<ViewUpdateSeamanCrewChanges>
}

export type LandingPageVesselSummary = {
  charterName: Scalars['String']['output']
  errorLevel: Scalars['String']['output']
  hasCrewChangesAtCanals: Scalars['Boolean']['output']
  hasManualSchedules: Scalars['Boolean']['output']
  hasNoCrewChanges: Scalars['Boolean']['output']
  hasNoSchedules: Scalars['Boolean']['output']
  hasUnassignedScheduleIds: Scalars['Boolean']['output']
  hasUnassignedScheduleIdsInFuture: Scalars['Boolean']['output']
  id: Scalars['UUID']['output']
  imo: Scalars['Int']['output']
  tenantId: Scalars['UUID']['output']
  uniqueOffsigners: Scalars['Boolean']['output']
  uniqueOnsigners: Scalars['Boolean']['output']
  unknownSeamen: Scalars['BigInt']['output']
  vesselScheduleSource: VesselScheduleSource
}

export type LandingPageVesselsOverview = {
  dataSyncLog: Maybe<DataSyncLog>
  erroneousVessels: Array<ViewVesselWithErroneousCrewChange>
  vessels: Array<LandingPageVesselSummary>
}

export enum LastDataSyncType {
  CrewLists = 'CrewLists',
  VesselSchedules = 'VesselSchedules',
}

export enum LastTicketingTimeSource {
  Amadeus = 'Amadeus',
  Automatic = 'Automatic',
  Manual = 'Manual',
}

export type ManningAgency = {
  active: Scalars['Boolean']['output']
  city: Maybe<Scalars['String']['output']>
  countryAlpha2: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  hanseaticAgencyId: Scalars['BigInt']['output']
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  users: Array<ManningAgencyUser>
}

export type ManningAgencyUser = {
  manningAgencyId: Scalars['UUID']['output']
  user: SafePartialUser
}

export type ManningAgencyUserForVessel = {
  id: Scalars['UUID']['output']
  manningAgencyUser: ManningAgencyUser
  manningAgencyUserId: Scalars['UUID']['output']
}

export type ManualScheduleCsvColumns = {
  errorMessage: Maybe<Scalars['String']['output']>
  eta: Maybe<Scalars['String']['output']>
  etd: Maybe<Scalars['String']['output']>
  imo: Scalars['String']['output']
  port: Scalars['String']['output']
  vessel: Scalars['String']['output']
}

export type MeUpdateInput = {
  emailSignature?: InputMaybe<Scalars['String']['input']>
}

export type MonitoringPreferences = {
  autoSwitchTicketedFlights: Scalars['Boolean']['output']
  unmonitorInHoursBeforeDeparture: Scalars['Int']['output']
}

export type Mutation = {
  addManualVesselSchedule: VesselSchedule
  addPnrSelectionData: Maybe<Scalars['Void']['output']>
  /** Adds a scenario with the provided critera */
  addScenario: PortPlanningScenario
  addVesselSchedule: VesselSchedule
  /** Marks the provided document as archived in the database */
  archiveDocument: CrewChangeDocument
  /** Automatically searches flights for the provided crew change seamen and search params */
  autoSearchFlights: Array<FlightBooking>
  /** Automatically updates unreviewed PNR LTDs which meet certain criteria */
  autoUpdatePNRLTDs: Maybe<Scalars['Void']['output']>
  bookUnselected: Array<FlightBooking>
  booking: FlightBookingMutation
  bookingRefund: FlightBookingRefundMutation
  bookings: FlightBookingsMutation
  /** Cancels evaluation of the provided scenarios */
  cancelEvaluateScenarios: Maybe<Scalars['Void']['output']>
  copyPnr: Array<FlightBooking>
  createBookingFromPnr: FlightBooking
  createBookingFromScratch: FlightBooking
  createCrewChange: Scalars['String']['output']
  /** Uploads a document to s3 and creates a document record in the database */
  createCrewChangeDocument: CrewChangeDocument
  createDemoCrewChange: Scalars['UUID']['output']
  createManualSchedulesFromCsv: Array<ManualScheduleCsvColumns>
  /** Creates a split ticketing booking entity, without any bookings attached */
  createSplitTicketingBooking: SplitTicketingBooking
  crewChange: CrewChangeMutation
  crewListEntry: CrewListEntryMutation
  deferFlightBooking: Array<FlightBooking>
  deferFlightCancellation: Array<FlightBooking>
  deferFlightSearch: CrewChangeSeaman
  deferFlightTicketing: Array<FlightBooking>
  /** Permanently deletes the provided document from s3 and removes the document record from the database */
  deleteDocument: Maybe<Scalars['Void']['output']>
  deleteErroneousCrewChanges: Maybe<Scalars['Void']['output']>
  deleteLandingVessel: Maybe<Scalars['Void']['output']>
  deletePnr: Scalars['Boolean']['output']
  detectFlightItineraryChanges: Scalars['Boolean']['output']
  dismissFlightBookingCheaperOffer: Maybe<Scalars['Void']['output']>
  editBookingFromScratch: FlightBooking
  editPnr: FlightBooking
  /** Edits schedule for the provided scenario */
  editScenario: PortPlanningScenario
  /** Evaluates the provided scenarios */
  evaluateScenarios: Maybe<Scalars['Void']['output']>
  findAndSaveFlightRecommendations: Array<FlightRecommendationResult>
  invitePortAgent: SafePartialUser
  landingAction: Maybe<Scalars['Void']['output']>
  linkScheduleToCrewChange: Maybe<Scalars['Void']['output']>
  logout: Maybe<Scalars['Void']['output']>
  /**
   * Marks a cheaper flight booking offer as switched by the user at the current timestamp
   * This is an idempotent operation; once the timestamp is set, it cannot be changed or updated,
   * and the operation will return the same result if called again.
   */
  markCheaperOfferAsSwitched: Maybe<FlightOfferNotification>
  /** Soft-deletes / marks the given scenario as inactive */
  markScenarioAsInactive: Maybe<Scalars['Void']['output']>
  markSeaportAsPlanned: VesselSchedule
  me: User
  notifyLTDNearby: Maybe<Scalars['Void']['output']>
  /** Adds a scenario with pre-defined criteria and minimal user input */
  quickAddScenario: PortPlanningScenario
  /** Replaces and archives the provided schedule and links the replacement with any crew changes linked to the existing schedule */
  replaceVesselSchedule: Maybe<Scalars['Void']['output']>
  repricePnr: Array<FlightBooking>
  resetPortPlanning: Maybe<Scalars['Void']['output']>
  resetSyncJob: Maybe<Scalars['Void']['output']>
  revertFlightBooking: Array<FlightBooking>
  /** Select FastFlight, returned FlightBooking linked to persisted Flight. */
  selectFastFlight: FlightBooking
  selectFlight: FlightBooking
  shareFlightsDetails: Scalars['Void']['output']
  transferFlightBookingToDifferentCrewChange: Array<FlightBooking>
  /** Marks the provided document as unarchived in the database */
  unarchiveDocument: CrewChangeDocument
  updateAddedPnrs: Maybe<Scalars['Void']['output']>
  updateCrewChange: Scalars['String']['output']
  /** Update dismissed/read state of notifications */
  updateNotificationsState: Array<VesselNotifications>
  updatePNRLTD: Maybe<Scalars['Void']['output']>
  updateTravelOffice: Maybe<Scalars['Void']['output']>
  vessel: Maybe<VesselMutation>
  vesselSchedule: Maybe<VesselScheduleMutation>
  wipeLandingTables: Maybe<Scalars['Void']['output']>
}

export type MutationAddManualVesselScheduleArgs = {
  params: AddManualVesselScheduleInput
}

export type MutationAddPnrSelectionDataArgs = {
  pnr: Scalars['String']['input']
  possibleLtdSelections: Array<PossiblePnrLtdSelection>
  selectedLtd: Scalars['DateTime']['input']
  xml: Scalars['String']['input']
}

export type MutationAddScenarioArgs = {
  params: AddPortPlanningScenarioInput
}

export type MutationAddVesselScheduleArgs = {
  params: AddVesselScheduleInput
}

export type MutationArchiveDocumentArgs = {
  documentId: Scalars['UUID']['input']
}

export type MutationAutoSearchFlightsArgs = {
  params: AutoSearchFlightsParams
}

export type MutationBookUnselectedArgs = {
  params: BookUnselectedInput
}

export type MutationBookingArgs = {
  id: Scalars['UUID']['input']
}

export type MutationBookingRefundArgs = {
  id: Scalars['UUID']['input']
}

export type MutationBookingsArgs = {
  ids: Array<Scalars['UUID']['input']>
}

export type MutationCancelEvaluateScenariosArgs = {
  params: CancelEvaluateScenarioInput
}

export type MutationCopyPnrArgs = {
  bookingId: Scalars['UUID']['input']
  seamanIds: Array<Scalars['UUID']['input']>
}

export type MutationCreateBookingFromPnrArgs = {
  params: CreateBookingFromPnrInput
}

export type MutationCreateBookingFromScratchArgs = {
  params: CreateBookingFromScratchInput
}

export type MutationCreateCrewChangeArgs = {
  params: CreateCrewChangeInput
}

export type MutationCreateCrewChangeDocumentArgs = {
  bucketId: Scalars['UUID']['input']
  file: Scalars['Upload']['input']
  seamanId?: InputMaybe<Scalars['UUID']['input']>
  type: DocumentType
}

export type MutationCreateDemoCrewChangeArgs = {
  params: CreateDemoCrewChangeInput
}

export type MutationCreateManualSchedulesFromCsvArgs = {
  file: Scalars['Upload']['input']
}

export type MutationCrewChangeArgs = {
  bucketId: Scalars['UUID']['input']
}

export type MutationCrewListEntryArgs = {
  id: Scalars['UUID']['input']
}

export type MutationDeferFlightBookingArgs = {
  params: DeferFlightsParams
}

export type MutationDeferFlightCancellationArgs = {
  params: DeferFlightsParams
}

export type MutationDeferFlightSearchArgs = {
  params: DeferFlightSearchParams
}

export type MutationDeferFlightTicketingArgs = {
  params: DeferFlightsParams
}

export type MutationDeleteDocumentArgs = {
  documentId: Scalars['UUID']['input']
}

export type MutationDeleteErroneousCrewChangesArgs = {
  crewHash: Scalars['String']['input']
  imo: Scalars['Int']['input']
}

export type MutationDeleteLandingVesselArgs = {
  imo: Scalars['Int']['input']
}

export type MutationDeletePnrArgs = {
  bookingId: Scalars['UUID']['input']
}

export type MutationDetectFlightItineraryChangesArgs = {
  params: DetectFlightItineraryChangesParams
}

export type MutationDismissFlightBookingCheaperOfferArgs = {
  cheaperOfferId: Scalars['UUID']['input']
}

export type MutationEditBookingFromScratchArgs = {
  params: EditBookingFromScratchInput
}

export type MutationEditPnrArgs = {
  params: EditPnrInput
}

export type MutationEditScenarioArgs = {
  params: EditPortPlanningScenarioInput
}

export type MutationEvaluateScenariosArgs = {
  params: EvaluateScenarioInput
}

export type MutationFindAndSaveFlightRecommendationsArgs = {
  params: FlightRecommendationInput
}

export type MutationInvitePortAgentArgs = {
  params: InvitePortAgentInput
}

export type MutationLandingActionArgs = {
  action: DataSyncAction
}

export type MutationLinkScheduleToCrewChangeArgs = {
  crewHash: Scalars['String']['input']
  vesselScheduleSourceId: Scalars['String']['input']
}

export type MutationMarkCheaperOfferAsSwitchedArgs = {
  bookingIdSwitchedTo: Scalars['UUID']['input']
  cheaperOfferId: Scalars['UUID']['input']
}

export type MutationMarkScenarioAsInactiveArgs = {
  scenarioId: Scalars['UUID']['input']
}

export type MutationMarkSeaportAsPlannedArgs = {
  bucketId: Scalars['String']['input']
  isPlanned?: InputMaybe<Scalars['Boolean']['input']>
  vesselScheduleId: Scalars['UUID']['input']
}

export type MutationMeArgs = {
  data: MeUpdateInput
}

export type MutationNotifyLtdNearbyArgs = {
  pnr: Scalars['String']['input']
}

export type MutationQuickAddScenarioArgs = {
  params: QuickAddPortPlanningScenarioInput
}

export type MutationReplaceVesselScheduleArgs = {
  replacementScheduleId: Scalars['UUID']['input']
  scheduleId: Scalars['UUID']['input']
}

export type MutationRepricePnrArgs = {
  bucketId: Scalars['String']['input']
  pnr: Scalars['String']['input']
}

export type MutationResetPortPlanningArgs = {
  bucketId: Scalars['UUID']['input']
}

export type MutationRevertFlightBookingArgs = {
  params: RevertFlightBookingDeferralParams
}

export type MutationSelectFastFlightArgs = {
  params: FastFlightSelectParams
}

export type MutationSelectFlightArgs = {
  params: FlightSelectParams
}

export type MutationShareFlightsDetailsArgs = {
  params: ShareFlightsDetailsParams
}

export type MutationTransferFlightBookingToDifferentCrewChangeArgs = {
  params: TransferFlightBookingToDifferentCrewChangeParams
}

export type MutationUnarchiveDocumentArgs = {
  documentId: Scalars['UUID']['input']
}

export type MutationUpdateAddedPnrsArgs = {
  bucketId: Scalars['UUID']['input']
}

export type MutationUpdateCrewChangeArgs = {
  params: UpdateCrewChangeInput
}

export type MutationUpdateNotificationsStateArgs = {
  notificationsToUpdate: Array<NotificationToUpdate>
  notificationsUpdateType?: InputMaybe<NotificationUpdateType>
}

export type MutationUpdatePnrltdArgs = {
  ltd: Scalars['DateTime']['input']
  pnr: Scalars['String']['input']
}

export type MutationUpdateTravelOfficeArgs = {
  active: Scalars['Boolean']['input']
  id: Scalars['UUID']['input']
}

export type MutationVesselArgs = {
  id: Scalars['UUID']['input']
}

export type MutationVesselScheduleArgs = {
  id: Scalars['UUID']['input']
}

export type Notification = {
  createdAt: Scalars['DateTime']['output']
  dismissed: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  read: Scalars['Boolean']['output']
}

export type NotificationToUpdate = {
  id: Scalars['UUID']['input']
  /** Whether to update the read or dismissed state */
  type: NotificationType
  /** Value to apply to the read or dismissed state */
  value: Scalars['Boolean']['input']
}

export enum NotificationType {
  ChangedSegments = 'ChangedSegments',
  CheaperOffers = 'CheaperOffers',
}

export enum NotificationUpdateType {
  Dismiss = 'Dismiss',
  Read = 'Read',
}

/** Result of flight offer text parsed by OpenAI */
export type ParsedFlightOffer = {
  /** Baggage pieces allowance */
  baggagePieces: Maybe<Scalars['Int']['output']>
  /** Baggage weight allowance in kilograms */
  baggageWeight: Maybe<Scalars['Float']['output']>
  /** Total fare amount */
  fareAmount: Maybe<Scalars['Int']['output']>
  /** Fare basis code */
  fareBasisCode: Maybe<Scalars['String']['output']>
  /** Currency of the fareAmount */
  fareCurrency: Maybe<Currency>
  /** Last Ticketing Date */
  ltd: Maybe<Scalars['DateTime']['output']>
  /** Marketing Carrier */
  marketingCarrier: Maybe<Scalars['String']['output']>
  /** Refundability of the ticket */
  refundable: Maybe<ParsedRefundInfo>
  /** Flight segments parsed */
  segments: Array<Maybe<ParsedSegment>>
}

export enum ParsedRefundInfo {
  NonRefundable = 'NonRefundable',
  PartiallyRefundable = 'PartiallyRefundable',
  Refundable = 'Refundable',
}

export type ParsedSegment = {
  arrival: ParsedSegmentInfo
  carrier: Scalars['String']['output']
  departure: ParsedSegmentInfo
  flightNumber: Scalars['Int']['output']
}

export type ParsedSegmentInfo = {
  dateTime: Scalars['DateTime']['output']
  iata: Scalars['String']['output']
}

export type ParsedSegmentInfoInput = {
  dateTime: Scalars['DateTime']['input']
  iata: Scalars['String']['input']
}

export type ParsedSegmentInput = {
  arrival: ParsedSegmentInfoInput
  carrier: Scalars['String']['input']
  departure: ParsedSegmentInfoInput
  flightNumber: Scalars['Int']['input']
}

export enum PassengerType {
  Adult = 'Adult',
  Seaman = 'Seaman',
}

export enum PenaltyCalculationType {
  Amount = 'Amount',
  Percent = 'Percent',
}

export type PnrInfo = {
  fares: Maybe<Array<PnrInfoFare>>
  marketingCarriers: Array<Scalars['String']['output']>
  officeId: Maybe<Scalars['String']['output']>
  originalLTD: Maybe<Scalars['DateTime']['output']>
  possibleLtds: Array<PnrInfoPossibleLtd>
  rawPnr: Scalars['String']['output']
}

export type PnrInfoFare = {
  amount: Maybe<Scalars['Int']['output']>
  baggageDetails: Maybe<BaggageDetails>
  currency: Currency
  lastTicketingDate: Maybe<Scalars['DateTime']['output']>
  tax: Maybe<Scalars['Int']['output']>
}

export type PnrInfoParams = {
  pnr: Scalars['String']['input']
  seamanId?: InputMaybe<Scalars['String']['input']>
  withTstInfo?: InputMaybe<Scalars['Boolean']['input']>
}

export type PnrInfoPossibleLtd = {
  offset: Scalars['String']['output']
  originalLtd: Scalars['String']['output']
  score: Maybe<Scalars['Float']['output']>
  source: Scalars['String']['output']
  timezone: Scalars['String']['output']
  utcLtd: Scalars['String']['output']
}

export type PortAgency = {
  address: Maybe<Scalars['String']['output']>
  city: Maybe<Scalars['String']['output']>
  countryAlpha2: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  email: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  phone: Maybe<Scalars['String']['output']>
  portAgencyServices: Array<PortAgencyService>
  portAgents: Array<PortAgencyUser>
  sourceId: Scalars['String']['output']
  state: Maybe<Scalars['String']['output']>
  tenants: Array<Tenant>
  type: PortAgencyType
  updatedAt: Scalars['DateTime']['output']
  zipCode: Maybe<Scalars['String']['output']>
}

export type PortAgencyService = {
  category: PortAgencyServiceCategory
  createdAt: Scalars['DateTime']['output']
  currencyAlpha3: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  portAgency: PortAgency
  priceMax: Maybe<Scalars['String']['output']>
  priceMin: Maybe<Scalars['String']['output']>
  tenant: Tenant
  unit: PortAgencyServiceUnit
  updatedAt: Scalars['DateTime']['output']
}

export enum PortAgencyServiceCategory {
  General = 'General',
  Hotel = 'Hotel',
  Transport = 'Transport',
}

export enum PortAgencyServiceUnit {
  Km = 'Km',
  Pax = 'Pax',
  PaxPerUnit = 'PaxPerUnit',
  Trip = 'Trip',
  Vsl = 'Vsl',
}

export enum PortAgencyType {
  Crewing = 'Crewing',
  CrewingAndTech = 'CrewingAndTech',
  Tech = 'Tech',
}

export type PortAgencyUser = {
  createdAt: Scalars['DateTime']['output']
  emailSecondary: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  mobile: Maybe<Scalars['String']['output']>
  portAgency: PortAgency
  role: Maybe<Scalars['String']['output']>
  tenant: Tenant
  updatedAt: Scalars['DateTime']['output']
  user: SafePartialUser
}

export type PortPlanningScenario = {
  active: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  createdBy: SafePartialUser
  flights: Maybe<Array<FlightRecommendation>>
  id: Scalars['UUID']['output']
  seaport: Seaport
  tenant: Tenant
  updatedAt: Scalars['DateTime']['output']
  updatedBy: Maybe<SafePartialUser>
  vesselSchedule: VesselSchedule
}

export type PortPlanningScenarioEvaluationData = {
  currency: Scalars['String']['output']
  feasibility: Scalars['String']['output']
  safeOperations: Scalars['String']['output']
  scenarioCost: Scalars['Float']['output']
  seafarerWellbeing: Scalars['String']['output']
}

export type PortPlanningScenarioEvaluationResult = {
  evaluationData: Maybe<PortPlanningScenarioEvaluationData>
  scenarioId: Scalars['UUID']['output']
  state: ScenarioEvaluationState
}

export type PortPlanningScenarioNewScheduleInput = {
  ETA: Scalars['DateTime']['input']
  portStay: Scalars['Int']['input']
  scheduleTimezone: Scalars['String']['input']
}

export type PortPlanningScenariosOverviewResult = {
  crewChangeSeamanDetails: Array<CrewChangeSeamanForCreation>
  scenarios: Array<PortPlanningScenario>
  schedules: Array<ValidSchedule>
  vessel: Vessel
}

export type PossiblePnrLtdSelection = {
  line: Scalars['String']['input']
  ltd: Scalars['DateTime']['input']
  ltdUtc: Scalars['DateTime']['input']
  timezone: Scalars['String']['input']
}

export type PriceAndFareOfferResult = {
  isFareForRequestedBookingClasses: Scalars['Boolean']['output']
  offer: FlightOffer
}

export type PriceAndFareVilRecommendationResult = {
  amount: Scalars['Float']['output']
  offers: Array<PriceAndFareOfferResult>
}

export type PriceVilRecommendationInput = {
  flights: Array<FastFlightInput>
}

export enum QuarantineFacility {
  CertifiedFacility = 'CertifiedFacility',
  GovernmentFacility = 'GovernmentFacility',
  PrivateFacility = 'PrivateFacility',
}

export type QuarantineInformation = {
  quarantineAtOwnExpense: Scalars['Boolean']['output']
  quarantineFacility: Maybe<QuarantineFacility>
}

export type QuarantineRequirement = {
  id: Scalars['UUID']['output']
  quarantineTime: Scalars['Int']['output']
  vaccinationStatus: VaccinationStatus
}

export type Query = {
  airline: Maybe<Airline>
  airport: Maybe<Airport>
  airports: Array<Airport>
  /** Returns all valid vessels for the current tenant */
  allVessels: Array<Vessel>
  booking: FlightBooking
  bookingHistory: Array<BookingHistoryRow>
  bookingInfo: BookingInfo
  bookings: Array<FlightBooking>
  bookingsByPnr: Array<FlightBookingSummary>
  connectionRecommendations: Array<ConnectionRecommendation>
  covidRestrictions: Array<CovidRestrictionForLeg>
  crewChange: CrewChange
  crewChangeDocuments: Array<CrewChangeDocument>
  crewChangeDocumentsUrls: Array<CrewChangeDocumentDownloadUrl>
  crewChangeInfo: CrewChangeInfo
  crewChangeSeamenDetail: Array<CrewChangeSeamanForCreation>
  crewChangeSeamenForRank: Array<CrewChangeSeamanForCreation>
  crewChangeSeamenForRanks: Array<CrewChangeSeamenForRanksResponse>
  crewChangeSeamenForVessel: Array<CrewChangeSeamanForCreation>
  crewChanges: Array<CrewChangeOverview>
  crewChangesLastDataSync: Maybe<CrewChangesDataSync>
  crewChangesWithSameCrew: Array<CrewChangeOverview>
  currencyRates: Maybe<CurrencyRate>
  currentTenant: Tenant
  currentTravelOffices: Array<TravelOfficeConfiguration>
  /**
   * Returns a list of deferrals that match the specified deferral type. It can either return
   * incomplete or completed deferrals depending on whether the completed parameter has been passed.
   */
  deferrals: DeferralList
  /** Returns a summary of pending deferrals for all types: search, booking, ticketing, and cancellation */
  deferralsSummary: DeferralsOverviewSummary
  flight: Maybe<Flight>
  flightOfferNotification: FlightOfferNotification
  getConflictingSeamenForCrewChange: Array<CrewChangeSeamenConflict>
  /** Provides the last data sync timestamp for the provided source */
  getLastDataSync: Scalars['DateTime']['output']
  /** Returns the amount of seats available for the flight segment with the lowest amount of seats available for a given offer ID */
  getOfferMinimumSeatsAvailable: Scalars['Int']['output']
  getShareFlightsEmail: Scalars['String']['output']
  landingVessel: LandingPageVesselDetail
  landingVessels: LandingPageVesselsOverview
  me: User
  /** Get all notifications by vessel */
  notifications: Array<VesselNotifications>
  parseFlightOffer: ParsedFlightOffer
  pnrInfo: PnrInfo
  popularAirports: Array<Airport>
  /** Returns an array of port planning scenarios already evaluated for all valid vessels for the current user */
  portPlanningScenariosOverview: Array<PortPlanningScenariosOverviewResult>
  /** Checks final fare and price for FastFlight. FlightOffer returned is persisted one */
  priceAndFareFastFlight: PriceAndFareOfferResult
  /** Checks final fare and price for FastFlight. Returns persisted requested and alternative offers */
  priceAndFareFastFlightWithAlternativeOffers: Array<PriceAndFareOfferResult>
  /** Checks final fare and price for VIL recommendation (comprised of FastFlights) */
  priceAndFareVilRecommendation: PriceAndFareVilRecommendationResult
  /** Returns a list of vessels that had recent crew changes */
  recentVessels: Array<Vessel>
  recentlyViewedPNRs: Array<LandingPnrSummary>
  refreshAuth: Maybe<Scalars['Void']['output']>
  seaman: Seaman
  seamanDocumentUrl: Array<SeamanDocumentDownloadUrl>
  seamenByRank: Array<Seaman>
  seamenForDemo: Array<Seaman>
  seaports: Array<Seaport>
  /** Returns a list of crew change seaman details that match the search string */
  searchCrewChangeSeamanDetails: Array<CrewChangeSeamanForCreation>
  searchFlights: FlightSearchResult
  searchRebookFlights: Array<Flight>
  /** Returns a list of seamen that match the search string */
  searchSeamen: Array<Seaman>
  shouldRepricePnr: Scalars['Boolean']['output']
  /**
   * Returns a list of tenants; this list will only have 1 element for non-dev environments,
   * as the tenant name will be taken from the subdomain of the request.
   * For dev environments, all tenants are returned.
   */
  tenantsForDomain: Array<Tenant>
  /** Returns a list of tenants for which the user has a registered account */
  tenantsForUser: Array<Tenant>
  travelWindow: TravelWindow
  unreviewedPNRs: Array<LandingPnrSummary>
  validSchedules: Array<ValidSchedule>
  /** Queries and returns valid schedules for the provded vessel */
  validSchedulesForVessel: Array<ValidSchedule>
  /** Returns a list of vessels that either match the search string or the IMOs */
  vessels: Array<Vessel>
  visasForCountry: Array<Visa>
}

export type QueryAirlineArgs = {
  iata?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type QueryAirportArgs = {
  iata: Scalars['String']['input']
}

export type QueryAirportsArgs = {
  iatas?: InputMaybe<Array<Scalars['String']['input']>>
  search?: InputMaybe<Scalars['String']['input']>
}

export type QueryBookingArgs = {
  id: Scalars['UUID']['input']
}

export type QueryBookingHistoryArgs = {
  bucketId: Scalars['UUID']['input']
}

export type QueryBookingInfoArgs = {
  bookingId: Scalars['UUID']['input']
}

export type QueryBookingsArgs = {
  ids: Array<Scalars['UUID']['input']>
}

export type QueryBookingsByPnrArgs = {
  pnrQuery: Scalars['String']['input']
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
}

export type QueryConnectionRecommendationsArgs = {
  fromIatas: Array<Scalars['String']['input']>
  toIatas: Array<Scalars['String']['input']>
}

export type QueryCovidRestrictionsArgs = {
  legs: Array<CovidRestrictionsLegInput>
}

export type QueryCrewChangeArgs = {
  bucketId: Scalars['UUID']['input']
  endAt?: InputMaybe<Scalars['DateTime']['input']>
  startAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryCrewChangeDocumentsArgs = {
  bucketId: Scalars['UUID']['input']
}

export type QueryCrewChangeDocumentsUrlsArgs = {
  documentIds: Array<InputMaybe<Scalars['UUID']['input']>>
}

export type QueryCrewChangeInfoArgs = {
  bucketId: Scalars['UUID']['input']
}

export type QueryCrewChangeSeamenDetailArgs = {
  take: Scalars['Int']['input']
  vesselId: Scalars['UUID']['input']
}

export type QueryCrewChangeSeamenForRankArgs = {
  rankId: Scalars['Int']['input']
}

export type QueryCrewChangeSeamenForRanksArgs = {
  rankIds: Array<Scalars['Int']['input']>
}

export type QueryCrewChangeSeamenForVesselArgs = {
  scheduleOrId?: InputMaybe<Scalars['UUID']['input']>
  vesselOrId: Scalars['UUID']['input']
}

export type QueryCrewChangesArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>
  cancelled?: InputMaybe<Scalars['Boolean']['input']>
  completed?: InputMaybe<Scalars['Boolean']['input']>
  endAt: Scalars['DateTime']['input']
  etd: Scalars['DateTime']['input']
  startAt: Scalars['DateTime']['input']
}

export type QueryCrewChangesWithSameCrewArgs = {
  bucketId: Scalars['UUID']['input']
}

export type QueryDeferralsArgs = {
  completed?: InputMaybe<Scalars['Boolean']['input']>
  searchString?: InputMaybe<Scalars['String']['input']>
  skip: Scalars['Int']['input']
  take: Scalars['Int']['input']
  type: DeferralType
}

export type QueryFlightArgs = {
  id: Scalars['UUID']['input']
  seamanNationalityIso?: InputMaybe<Scalars['String']['input']>
}

export type QueryFlightOfferNotificationArgs = {
  id: Scalars['UUID']['input']
}

export type QueryGetConflictingSeamenForCrewChangeArgs = {
  bucketId: Scalars['UUID']['input']
}

export type QueryGetLastDataSyncArgs = {
  type: LastDataSyncType
}

export type QueryGetOfferMinimumSeatsAvailableArgs = {
  offerId: Scalars['String']['input']
}

export type QueryGetShareFlightsEmailArgs = {
  bookingIds: Array<Scalars['String']['input']>
  options?: InputMaybe<GetShareFlightsEmailOptions>
}

export type QueryLandingVesselArgs = {
  id: Scalars['UUID']['input']
}

export type QueryParseFlightOfferArgs = {
  offerText: Scalars['String']['input']
}

export type QueryPnrInfoArgs = {
  params: PnrInfoParams
}

export type QueryPopularAirportsArgs = {
  alpha2: Scalars['String']['input']
}

export type QueryPriceAndFareFastFlightArgs = {
  flight: FastFlightInput
  seamanNationalityIso?: InputMaybe<Scalars['String']['input']>
}

export type QueryPriceAndFareFastFlightWithAlternativeOffersArgs = {
  flight: FastFlightInput
}

export type QueryPriceAndFareVilRecommendationArgs = {
  recommendation: PriceVilRecommendationInput
}

export type QuerySeamanArgs = {
  id: Scalars['UUID']['input']
}

export type QuerySeamanDocumentUrlArgs = {
  seamanId: Scalars['UUID']['input']
  type: SeamanDocumentType
}

export type QuerySeamenByRankArgs = {
  rankId: Scalars['Int']['input']
}

export type QuerySeaportsArgs = {
  locodes?: InputMaybe<Array<Scalars['String']['input']>>
  search?: InputMaybe<Scalars['String']['input']>
}

export type QuerySearchCrewChangeSeamanDetailsArgs = {
  rankId?: InputMaybe<Scalars['Int']['input']>
  search: Scalars['String']['input']
}

export type QuerySearchFlightsArgs = {
  params: FlightSearchParams
}

export type QuerySearchRebookFlightsArgs = {
  bookingId: Scalars['UUID']['input']
  rebookParams: RebookSearchParams
  searchParams: FlightSearchParams
}

export type QuerySearchSeamenArgs = {
  search?: InputMaybe<Scalars['String']['input']>
}

export type QueryShouldRepricePnrArgs = {
  bucketId: Scalars['String']['input']
  pnr: Scalars['String']['input']
}

export type QueryTravelWindowArgs = {
  crewChangeSeamanId: Scalars['UUID']['input']
}

export type QueryValidSchedulesArgs = {
  bucketId?: InputMaybe<Scalars['UUID']['input']>
  vesselId: Scalars['UUID']['input']
}

export type QueryValidSchedulesForVesselArgs = {
  id: Scalars['UUID']['input']
}

export type QueryVesselsArgs = {
  imos?: InputMaybe<Array<Scalars['Int']['input']>>
  search?: InputMaybe<Scalars['String']['input']>
}

export type QueryVisasForCountryArgs = {
  carriersNationalitiesIso: Array<InputMaybe<Scalars['String']['input']>>
  countryNationalityIso: Scalars['String']['input']
}

export type QuickAddPortPlanningScenarioInput = {
  vesselId: Scalars['UUID']['input']
  vesselScheduleId: Scalars['UUID']['input']
}

export type RebookSearchParams = {
  allowedCarriers: Array<Scalars['String']['input']>
}

export type RestoreCrewChangeResult = {
  conflictingCrewChanges: Maybe<Array<CrewChangeOverview>>
  error: Maybe<Scalars['String']['output']>
  notUpdatedSeamen: Maybe<Array<Scalars['ID']['output']>>
  restoredCrewChange: Maybe<Scalars['ID']['output']>
  success: Scalars['Boolean']['output']
  updatedSeamen: Maybe<Array<Scalars['ID']['output']>>
}

export type RevertFlightBookingDeferralParams = {
  bookingIds: Array<Scalars['UUID']['input']>
  bucketId: Scalars['UUID']['input']
  deferralReversionType: DeferralReversionType
}

export type SafePartialUser = {
  city: Maybe<Scalars['String']['output']>
  countryAlpha2: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  emailSignature: Maybe<Scalars['String']['output']>
  fernandHash: Maybe<Scalars['String']['output']>
  firstName: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  lastName: Maybe<Scalars['String']['output']>
  phoneNumber: Maybe<Scalars['String']['output']>
  role: UserRole
  timezoneOlson: Maybe<Scalars['String']['output']>
}

export enum ScenarioEvaluationState {
  Cancelled = 'Cancelled',
  Error = 'Error',
  Evaluated = 'Evaluated',
  Evaluating = 'Evaluating',
  Idle = 'Idle',
  Queued = 'Queued',
}

export type Seaman = {
  birthday: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  firstName: Scalars['String']['output']
  firstVaccinationBrand: Maybe<Scalars['String']['output']>
  firstVaccinationDate: Maybe<Scalars['DateTime']['output']>
  flightBookings: Array<FlightBooking>
  hanseaticHomeAirportIata: Maybe<Scalars['String']['output']>
  hanseaticSeamanId: Scalars['BigInt']['output']
  homeAirportIata: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  lastName: Scalars['String']['output']
  manningAgency: Maybe<ManningAgency>
  nationalityIso: Scalars['String']['output']
  placeOfBirth: Maybe<Scalars['String']['output']>
  preferredAirport: Maybe<SeamanAirport>
  rank: Maybe<Scalars['Int']['output']>
  seamanBooks: Array<SeamanBook>
  seamanCommunications: Array<SeamanCommunication>
  seamanContracts: Array<SeamanContract>
  seamanPassports: Array<SeamanPassport>
  seamanRank: Maybe<SeamanRank>
  seamanVisas: Array<SeamanVisa>
  secondVaccinationBrand: Maybe<Scalars['String']['output']>
  secondVaccinationDate: Maybe<Scalars['DateTime']['output']>
  sex: Maybe<Scalars['String']['output']>
  tenant: Tenant
  updatedAt: Scalars['DateTime']['output']
}

export type SeamanFlightBookingsArgs = {
  bucketId?: InputMaybe<Scalars['UUID']['input']>
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>
}

export type SeamanAirport = {
  airport: Airport
  isHomeAirport: Scalars['Boolean']['output']
}

export type SeamanBook = {
  bookNumber: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  expiryAt: Maybe<Scalars['DateTime']['output']>
  hanseaticFileGuid: Maybe<Scalars['String']['output']>
  hanseaticInternalId: Maybe<Scalars['Int']['output']>
  id: Scalars['UUID']['output']
  isFlagStateSeamanBook: Scalars['Boolean']['output']
  issueAt: Maybe<Scalars['DateTime']['output']>
  nationalityAlpha2: Maybe<Scalars['String']['output']>
  s3DocumentKey: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type SeamanCommunication = {
  createdAt: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  type: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  value: Maybe<Scalars['String']['output']>
}

export enum SeamanConfirmationStatus {
  Confirmed = 'Confirmed',
  Pending = 'Pending',
  Unconfirmed = 'Unconfirmed',
}

export type SeamanContract = {
  contractFrom: Scalars['DateTime']['output']
  contractUntil: Scalars['DateTime']['output']
  createdAt: Scalars['DateTime']['output']
  hanseaticAssignmentId: Scalars['Int']['output']
  id: Scalars['UUID']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type SeamanCrewChangeConfirmationStatus = {
  id: Scalars['UUID']['output']
  note: Maybe<Scalars['String']['output']>
  status: SeamanConfirmationStatus
  user: SafePartialUser
}

export type SeamanDocumentDownloadUrl = {
  extension: Scalars['String']['output']
  presignedUrl: Scalars['String']['output']
}

export enum SeamanDocumentType {
  Passport = 'Passport',
  SeamanBook = 'SeamanBook',
  Visa = 'Visa',
}

export type SeamanPassport = {
  createdAt: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  passportExpiryAt: Scalars['DateTime']['output']
  passportFileGuid: Scalars['String']['output']
  passportInternalId: Scalars['Int']['output']
  passportIssueAt: Scalars['DateTime']['output']
  passportIssuePlace: Maybe<Scalars['String']['output']>
  passportNationalityIso: Scalars['String']['output']
  passportNumber: Scalars['String']['output']
  s3DocumentKey: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type SeamanRank = {
  acronym: Scalars['String']['output']
  createdAt: Maybe<Scalars['DateTime']['output']>
  hanseaticRankId: Scalars['Int']['output']
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  sortOrder: Maybe<Scalars['Int']['output']>
  updatedAt: Maybe<Scalars['DateTime']['output']>
}

export type SeamanVisa = {
  expiryAt: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  issueAt: Scalars['DateTime']['output']
  visaName: Scalars['String']['output']
  visaNumber: Scalars['String']['output']
}

export type Seaport = {
  alternateNames: Array<Scalars['String']['output']>
  berthLengthInMeters: Maybe<Scalars['Int']['output']>
  berthsCount: Maybe<Scalars['Int']['output']>
  countryAlpha2: Scalars['String']['output']
  covidTestingRequirements: Array<CovidTestingRequirement>
  craneCapacityInTonnes: Maybe<Scalars['Int']['output']>
  id: Scalars['UUID']['output']
  latitude: Scalars['Float']['output']
  locode: Scalars['String']['output']
  longitude: Scalars['Float']['output']
  maxVesselDraftInMeters: Maybe<Scalars['Int']['output']>
  maxVesselSizeInTonnes: Maybe<Scalars['Int']['output']>
  name: Scalars['String']['output']
  nameAlpha2: Maybe<Scalars['String']['output']>
  quarantineRequirements: Array<QuarantineRequirement>
  seaportStates: Array<SeaportState>
  timezoneOlson: Maybe<Scalars['String']['output']>
}

export type SeaportState = {
  active: Scalars['Boolean']['output']
  id: Scalars['UUID']['output']
}

export type SearchForCheaperOfferResult = {
  id: Scalars['UUID']['output']
}

export type SelectFlightSplitTicketingParams = {
  /** Index (ie order of the flight) of the selected booking within the split ticket */
  index: Scalars['Int']['input']
  /** Split ticketing booking that the selected booking will be attached to */
  splitTicketingBookingId: Scalars['UUID']['input']
}

export type ShareFlightsDetailsParams = {
  attachFlightDocuments?: InputMaybe<Scalars['Boolean']['input']>
  bcc?: InputMaybe<Array<Scalars['String']['input']>>
  bookingIds: Array<Scalars['String']['input']>
  cc?: InputMaybe<Array<Scalars['String']['input']>>
  content: Scalars['String']['input']
  eTicketFormat?: InputMaybe<ETicketEmailAttachmentFormat>
  emails: Array<Scalars['String']['input']>
  seamenRole?: InputMaybe<CrewChangeRole>
  subject: Scalars['String']['input']
}

export type SplitTicketingBooking = {
  id: Scalars['UUID']['output']
}

export type SplitTicketingInfo = {
  index: Scalars['Int']['output']
  splitTicketingBookingId: Scalars['UUID']['output']
}

export type StartCrewChangeEmail = {
  email: Scalars['String']['output']
  passportsAvailabilityStatus: DocumentsAvailableStatus
  seamanBooksAvailabilityStatus: DocumentsAvailableStatus
  visasAvailabilityStatus: DocumentsAvailableStatus
}

export type StartCrewChangeEmailParams = {
  includeDeactivatedSeamen?: InputMaybe<Scalars['Boolean']['input']>
  includeETickets?: InputMaybe<Scalars['Boolean']['input']>
  includePassportCopies?: InputMaybe<Scalars['Boolean']['input']>
  includeSeamanBookCopies?: InputMaybe<Scalars['Boolean']['input']>
  includeVisaCopies?: InputMaybe<Scalars['Boolean']['input']>
  includedSeafarers?: InputMaybe<IncludedSeafarersInEmails>
}

export type StartCrewChangeParams = {
  attachmentOptions: EmailAttachmentOptions
  bcc?: InputMaybe<Array<Scalars['String']['input']>>
  cc?: InputMaybe<Array<Scalars['String']['input']>>
  content: Scalars['String']['input']
  stakeholders: Array<Scalars['String']['input']>
  subject: Scalars['String']['input']
}

export type Subscription = {
  /** Triggered when a new document is added to a crew change, such as an e-ticket */
  crewChangeDocumentAdded: Maybe<CrewChangeDocumentEvent>
  /** Triggered when seafarer details are updated in a crew change */
  crewChangeSeamenUpdated: Seaman
  /** Trigerred when a port planning scenario is evaluated */
  portPlanningScenarioEvaluation: PortPlanningScenarioEvaluationResult
}

export type SubscriptionCrewChangeDocumentAddedArgs = {
  bucketId: Scalars['UUID']['input']
}

export type SubscriptionCrewChangeSeamenUpdatedArgs = {
  bucketId: Scalars['UUID']['input']
  seamenIds: Array<Scalars['UUID']['input']>
}

export type SubscriptionPortPlanningScenarioEvaluationArgs = {
  scenarioIds: Array<Scalars['UUID']['input']>
}

export type TaxDetail = {
  amount: Scalars['Float']['output']
  code: Maybe<Scalars['String']['output']>
  isNonRefundable: Maybe<Scalars['Boolean']['output']>
}

export type TemporaryCrewChangeResponse = {
  isExistingCrewChange: Scalars['Boolean']['output']
  url: Scalars['String']['output']
}

export type Tenant = {
  crewOperators: Array<TenantCrewOperator>
  crewSupervisors: Array<TenantCrewSupervisor>
  dataSourceSystem: DataSourceSystem
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  preferences: TenantPreferences
  subdomain: Scalars['String']['output']
  travelOfficeConfigurations: Array<TravelOfficeConfiguration>
}

export type TenantTravelOfficeConfigurationsArgs = {
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>
}

export type TenantCrewOperator = {
  firstName: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName: Maybe<Scalars['String']['output']>
}

export type TenantCrewSupervisor = {
  firstName: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName: Maybe<Scalars['String']['output']>
}

export type TenantPreferences = {
  autoTicketHoursBeforeLTD: Scalars['Int']['output']
  defaultETicketEmailAttachmentFormat: ETicketEmailAttachmentFormat
  id: Scalars['UUID']['output']
  includeTravelOfficeCommissionForFlightPriceComparison: Scalars['Boolean']['output']
  monitoringPreferences: Maybe<MonitoringPreferences>
  preferredCurrency: Scalars['String']['output']
  preferredFlightHistoryExportCurrency: Scalars['String']['output']
  preferredTravelOffice: Maybe<TravelOfficeConfiguration>
  travelAgent: Maybe<TenantTravelAgent>
}

export type TenantTravelAgent = {
  email: Scalars['String']['output']
  firstName: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  lastName: Maybe<Scalars['String']['output']>
  phoneNumber: Maybe<Scalars['String']['output']>
}

export enum TimeReference {
  BeforeArrival = 'BeforeArrival',
  BeforeDeparture = 'BeforeDeparture',
}

export enum TimeSpecifier {
  Arrival = 'Arrival',
  Departure = 'Departure',
}

export type TransferFlightBookingToDifferentCrewChangeParams = {
  currentBucketId: Scalars['UUID']['input']
  transferBucketId: Scalars['UUID']['input']
}

export type TravelOfficeConfiguration = {
  active: Scalars['Boolean']['output']
  agencyName: Scalars['String']['output']
  commission: Scalars['Int']['output']
  commissionPercentage: Scalars['Float']['output']
  currency: Scalars['String']['output']
  emailPrimary: Scalars['String']['output']
  id: Scalars['UUID']['output']
  officeId: Scalars['String']['output']
  phonePrimary: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export enum TravelPurpose {
  Entry = 'Entry',
  Exit = 'Exit',
  Transit = 'Transit',
}

export type TravelWindow = {
  endAt: Scalars['DateTime']['output']
  quarantineInformation: Maybe<QuarantineInformation>
  startAt: Scalars['DateTime']['output']
}

export type UpdateCrewChangeInput = {
  bucketId: Scalars['UUID']['input']
  /** ids for records in the `crewChangeSeamanDetails` table */
  crewChangeSeamanDetailsToAdd?: InputMaybe<Array<Scalars['UUID']['input']>>
  /** ids for records in the `crewChangeSeamen` table */
  crewChangeSeamenToRemove?: InputMaybe<Array<Scalars['UUID']['input']>>
  /** Whether or not to remove the temporary state from `vesselScheduleCrewChanges` record */
  removeTemporaryState?: InputMaybe<Scalars['Boolean']['input']>
  /** ids for records in `seamen` table to add */
  seamen?: InputMaybe<Array<CreateCrewChangeSeamanInput>>
  /** Id for the updated schedule */
  vesselScheduleId?: InputMaybe<Scalars['UUID']['input']>
}

export type User = {
  city: Maybe<Scalars['String']['output']>
  countryAlpha2: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  emailSignature: Maybe<Scalars['String']['output']>
  fernandHash: Maybe<Scalars['String']['output']>
  firstName: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  lastName: Maybe<Scalars['String']['output']>
  phoneNumber: Maybe<Scalars['String']['output']>
  role: UserRole
  tenant: Tenant
  timezoneOlson: Maybe<Scalars['String']['output']>
}

export { UserRole }

export enum VaccinationStatus {
  All = 'All',
  FullyVaccinated = 'FullyVaccinated',
  PartiallyVaccinated = 'PartiallyVaccinated',
  Unvaccinated = 'Unvaccinated',
}

export type ValidSchedule = {
  ETA: Scalars['DateTime']['output']
  ETB: Scalars['DateTime']['output']
  ETD: Scalars['DateTime']['output']
  /** Whether the current schedule is linked to a valid `vesselScheduleCrewChanges` record and its corresponding bucketId. This field doesn't exist in the database and is computed in the backend. */
  crewChangeLinkage: Maybe<CrewChangeLinkage>
  id: Scalars['UUID']['output']
  isPlannedSeaport: Scalars['Boolean']['output']
  isTemporary: Maybe<Scalars['Boolean']['output']>
  nearestAirports: Array<Airport>
  planned: Scalars['Boolean']['output']
  plannedSeaportId: Maybe<Scalars['String']['output']>
  portAgencies: Array<PortAgency>
  recommendations: Array<FlightRecommendationResult>
  seaport: Seaport
  source: VesselScheduleSource
  sourceId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  updatedBy: Maybe<SafePartialUser>
  vessel: Vessel
  voyageNumber: Maybe<Scalars['String']['output']>
}

export type ValidScheduleNearestAirportsArgs = {
  take?: InputMaybe<Scalars['Int']['input']>
}

export type Vessel = {
  activeCrewOperatorType: ActiveCrewOperatorType
  activeCrewSupervisorType: ActiveCrewSupervisorType
  additionalCrewOperatorsForVessel: Array<AdditionalCrewOperatorForVessel>
  backupCrewOperator: Maybe<SafePartialUser>
  backupCrewSupervisor: Maybe<SafePartialUser>
  callSign: Scalars['String']['output']
  charterName: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  crewManager: Maybe<SafePartialUser>
  flagstateCountryIsoCode: Maybe<Scalars['String']['output']>
  grossTonnage: Scalars['Float']['output']
  hanseaticVesselId: Scalars['Int']['output']
  id: Scalars['UUID']['output']
  imagePath: Scalars['String']['output']
  imo: Scalars['Int']['output']
  internalCode: Scalars['String']['output']
  manningAgencyUsersForVessels: Array<ManningAgencyUserForVessel>
  primaryCrewOperator: Maybe<SafePartialUser>
  primaryCrewSupervisor: Maybe<SafePartialUser>
  tenant: Tenant
  updatedAt: Scalars['DateTime']['output']
  vesselCommunicationDetails: Array<VesselCommunicationDetails>
  vesselScheduleSource: VesselScheduleSource
}

export type VesselCommunicationDetails = {
  id: Scalars['UUID']['output']
  imo: Scalars['Int']['output']
  type: CommunicationType
  value: Scalars['String']['output']
}

export type VesselMutation = {
  assignCrewOperator: Vessel
  assignCrewSupervisor: Vessel
  id: Scalars['UUID']['output']
}

export type VesselMutationAssignCrewOperatorArgs = {
  crewOperatorId: Scalars['UUID']['input']
  type: ActiveCrewOperatorType
}

export type VesselMutationAssignCrewSupervisorArgs = {
  crewSupervisorId: Scalars['UUID']['input']
  type: ActiveCrewSupervisorType
}

export type VesselNotifications = {
  bucketId: Scalars['String']['output']
  changedSegmentsNotifications: Array<ChangedSegmentsNotification>
  cheaperOfferNotifications: Array<CheaperOfferNotification>
  vessel: Vessel
  vesselSchedule: VesselSchedule
}

export type VesselSchedule = {
  COSP: Maybe<Scalars['DateTime']['output']>
  EOSP: Maybe<Scalars['DateTime']['output']>
  ETA: Scalars['DateTime']['output']
  ETB: Scalars['DateTime']['output']
  ETD: Scalars['DateTime']['output']
  TOA: Maybe<Scalars['DateTime']['output']>
  TOD: Maybe<Scalars['DateTime']['output']>
  bunkering: Scalars['Boolean']['output']
  cancelled: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  /** Whether the current schedule is linked to a valid `vesselScheduleCrewChanges` record and its corresponding bucketId. This field doesn't exist in the database and is computed in the backend. */
  crewChangeLinkage: Maybe<CrewChangeLinkage>
  discharging: Scalars['Boolean']['output']
  id: Scalars['UUID']['output']
  isPlannedSeaport: Scalars['Boolean']['output']
  loading: Scalars['Boolean']['output']
  plannedSeaportId: Maybe<Scalars['String']['output']>
  portCountry: Maybe<Scalars['String']['output']>
  portName: Maybe<Scalars['String']['output']>
  portTerminal: Maybe<Scalars['String']['output']>
  seaport: Seaport
  source: VesselScheduleSource
  sourceId: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
  updatedBy: Maybe<SafePartialUser>
  validFrom: Maybe<Scalars['DateTime']['output']>
  validUntil: Maybe<Scalars['DateTime']['output']>
  vessel: Vessel
  voyageNumber: Maybe<Scalars['String']['output']>
}

export type VesselScheduleCrewChange = {
  bucketId: Scalars['String']['output']
  cancelled: Scalars['Boolean']['output']
  completed: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  crewChange: CrewChange
  id: Scalars['UUID']['output']
  started: Scalars['Boolean']['output']
  updatedAt: Scalars['DateTime']['output']
  vessel: Vessel
  vesselSchedule: VesselSchedule
}

export type VesselScheduleMutation = {
  delete: Scalars['Boolean']['output']
  id: Scalars['UUID']['output']
  update: VesselSchedule
}

export type VesselScheduleMutationUpdateArgs = {
  ETA: Scalars['DateTime']['input']
  ETB: Scalars['DateTime']['input']
  ETD: Scalars['DateTime']['input']
  seaportId?: InputMaybe<Scalars['UUID']['input']>
}

export enum VesselScheduleSource {
  Arbitrary = 'Arbitrary',
  Beacon52 = 'Beacon52',
  Hanseatic = 'Hanseatic',
  Manual = 'Manual',
  Seacrew = 'Seacrew',
  Stolt = 'Stolt',
  Temporary = 'Temporary',
}

export type ViewErroneousCrewChanges = {
  crewHash: Scalars['String']['output']
  id: Scalars['UUID']['output']
  vesselId: Scalars['UUID']['output']
}

export type ViewUpdateCrewChanges = {
  createdAt: Scalars['String']['output']
  crewHash: Scalars['String']['output']
  crewOperatorId: Scalars['String']['output']
  crewSupervisorId: Scalars['String']['output']
  existingCrewChangeId: Scalars['String']['output']
  existingCrewHash: Scalars['String']['output']
  existingSeaportName: Scalars['String']['output']
  hanseaticNearestAirportIata: Scalars['String']['output']
  id: Scalars['UUID']['output']
  maxTime: Scalars['String']['output']
  minTime: Scalars['String']['output']
  scheduleId: Scalars['String']['output']
  scheduledPorts: Scalars['String']['output']
  seaportLocode: Scalars['String']['output']
  seaportName: Scalars['String']['output']
  startOn: Maybe<Scalars['Date']['output']>
  startOnDay: Scalars['String']['output']
  status: Scalars['String']['output']
  stopOn: Maybe<Scalars['Date']['output']>
  tenantId: Scalars['String']['output']
  updatedAt: Scalars['String']['output']
  vesselId: Scalars['String']['output']
  vesselImo: Maybe<Scalars['Int']['output']>
  vesselSourceId: Maybe<Scalars['String']['output']>
}

export type ViewUpdateSeamanCrewChanges = {
  birthday: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['String']['output']
  crewChangeId: Maybe<Scalars['String']['output']>
  crewHash: Scalars['String']['output']
  disembarkationAt: Maybe<Scalars['String']['output']>
  embarkationAt: Maybe<Scalars['String']['output']>
  firstName: Maybe<Scalars['String']['output']>
  homeAirportIata: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  isUnk: Scalars['Boolean']['output']
  lastName: Maybe<Scalars['String']['output']>
  manningAgencyId: Maybe<Scalars['String']['output']>
  passportExpiryAt: Maybe<Scalars['DateTime']['output']>
  passportIssueAt: Maybe<Scalars['DateTime']['output']>
  passportIssuePlace: Maybe<Scalars['String']['output']>
  passportNationalityIso: Maybe<Scalars['String']['output']>
  passportNumber: Maybe<Scalars['String']['output']>
  seamanId: Maybe<Scalars['String']['output']>
  sourceId: Maybe<Scalars['String']['output']>
  tenantId: Scalars['String']['output']
  type: LandingCrewChangeSeamanType
  updatedAt: Scalars['String']['output']
  vesselImo: Maybe<Scalars['String']['output']>
}

export type ViewVesselWithErroneousCrewChange = {
  charterName: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  crewHash: Maybe<Scalars['String']['output']>
  startOn: Maybe<Scalars['Date']['output']>
  tenantId: Maybe<Scalars['UUID']['output']>
  vesselId: Scalars['UUID']['output']
}

export type VirtualInterliningFlight = {
  flightId: Scalars['UUID']['output']
  number: Scalars['String']['output']
}

export type VirtualInterliningRecommendation = {
  amount: Scalars['Float']['output']
  currency: Scalars['String']['output']
  flightRecommendations: Array<VirtualInterliningFlight>
  recId: Scalars['String']['output']
}

export type Visa = {
  carrierNationalityIso: Scalars['String']['output']
  countryNationalityIso: Scalars['String']['output']
  feeAmount: Maybe<Scalars['Float']['output']>
  feeCurrency: Maybe<Currency>
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  required: Scalars['Boolean']['output']
  type: VisaType
}

export enum VisaOrCovidTestRequiredOn {
  OnArrival = 'OnArrival',
  PreArrival = 'PreArrival',
}

export enum VisaRequirementSource {
  PortAgent = 'PortAgent',
}

export enum VisaType {
  Consular = 'Consular',
  EVisa = 'EVisa',
  OnArrival = 'OnArrival',
}
