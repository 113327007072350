import clsx from 'clsx'
import { ReactNode } from 'react'
import type { TableVariant } from './Table'

interface TableHeadProps {
  variant?: TableVariant
  children: ReactNode
  className?: string
}

function TableHead({ children, variant = 'standalone', className }: TableHeadProps): JSX.Element {
  return (
    <thead
      className={clsx(
        'text-xs font-normal text-left text-gray-600 border-b border-gray-200',
        variant === 'standalone' && 'bg-gray-50',
        className
      )}
    >
      {children}
    </thead>
  )
}

export default TableHead
