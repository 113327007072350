import clsx from 'clsx'
import { ClassAttributes, createElement, HTMLAttributes } from 'react'

type TableRowProps = HTMLAttributes<HTMLTableRowElement> & ClassAttributes<HTMLTableRowElement>
function TableRow(props: TableRowProps): JSX.Element {
  const { className, ...otherProps } = props

  return createElement('tr', {
    ...otherProps,
    className: clsx('text-sm border-b border-gray-200 last:border-b-0', className),
  })
}

export default TableRow
