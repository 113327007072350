/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type LoadCurrencyRatesQueryVariables = Types.Exact<{ [key: string]: never }>

export type LoadCurrencyRatesQuery = {
  currencyRates: { EUR: number; USD: number; PHP: number } | null
}

export type LoadCovidRestrictionsQueryVariables = Types.Exact<{
  legs: Array<Types.CovidRestrictionsLegInput> | Types.CovidRestrictionsLegInput
}>

export type LoadCovidRestrictionsQuery = {
  covidRestrictions: Array<{
    arrival: string | null
    departure: string | null
    testRequirements: Array<{
      id: string
      title: string
      description: string
      testWindow: Array<{
        value: number
        type: string
        condition: string | null
        medicalRequirementType: string | null
        text: string
      }>
    }>
  }>
}

export type LoadVisasForCountryQueryVariables = Types.Exact<{
  countryNationalityIso: Types.Scalars['String']['input']
  carriersNationalitiesIso:
    | Array<Types.InputMaybe<Types.Scalars['String']['input']>>
    | Types.InputMaybe<Types.Scalars['String']['input']>
}>

export type LoadVisasForCountryQuery = {
  visasForCountry: Array<{
    id: string
    name: string
    type: Types.VisaType
    carrierNationalityIso: string
    countryNationalityIso: string
    required: boolean
    feeAmount: number | null
    feeCurrency: Types.Currency | null
  }>
}

export const LoadCurrencyRatesDocument = gql`
  query LoadCurrencyRates {
    currencyRates {
      EUR
      USD
      PHP
    }
  }
`

/**
 * __useLoadCurrencyRatesQuery__
 *
 * To run a query within a React component, call `useLoadCurrencyRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCurrencyRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCurrencyRatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadCurrencyRatesQuery(
  baseOptions?: Apollo.QueryHookOptions<LoadCurrencyRatesQuery, LoadCurrencyRatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoadCurrencyRatesQuery, LoadCurrencyRatesQueryVariables>(
    LoadCurrencyRatesDocument,
    options
  )
}
export function useLoadCurrencyRatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LoadCurrencyRatesQuery, LoadCurrencyRatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LoadCurrencyRatesQuery, LoadCurrencyRatesQueryVariables>(
    LoadCurrencyRatesDocument,
    options
  )
}
export function useLoadCurrencyRatesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<LoadCurrencyRatesQuery, LoadCurrencyRatesQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LoadCurrencyRatesQuery, LoadCurrencyRatesQueryVariables>(
    LoadCurrencyRatesDocument,
    options
  )
}
export type LoadCurrencyRatesQueryHookResult = ReturnType<typeof useLoadCurrencyRatesQuery>
export type LoadCurrencyRatesLazyQueryHookResult = ReturnType<typeof useLoadCurrencyRatesLazyQuery>
export type LoadCurrencyRatesSuspenseQueryHookResult = ReturnType<
  typeof useLoadCurrencyRatesSuspenseQuery
>
export type LoadCurrencyRatesQueryResult = Apollo.QueryResult<
  LoadCurrencyRatesQuery,
  LoadCurrencyRatesQueryVariables
>
export const LoadCovidRestrictionsDocument = gql`
  query LoadCovidRestrictions($legs: [CovidRestrictionsLegInput!]!) {
    covidRestrictions(legs: $legs) {
      arrival
      departure
      testRequirements {
        id
        title
        description
        testWindow {
          value
          type
          condition
          medicalRequirementType
          text
        }
      }
    }
  }
`

/**
 * __useLoadCovidRestrictionsQuery__
 *
 * To run a query within a React component, call `useLoadCovidRestrictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCovidRestrictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCovidRestrictionsQuery({
 *   variables: {
 *      legs: // value for 'legs'
 *   },
 * });
 */
export function useLoadCovidRestrictionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadCovidRestrictionsQuery,
    LoadCovidRestrictionsQueryVariables
  > &
    ({ variables: LoadCovidRestrictionsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoadCovidRestrictionsQuery, LoadCovidRestrictionsQueryVariables>(
    LoadCovidRestrictionsDocument,
    options
  )
}
export function useLoadCovidRestrictionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadCovidRestrictionsQuery,
    LoadCovidRestrictionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LoadCovidRestrictionsQuery, LoadCovidRestrictionsQueryVariables>(
    LoadCovidRestrictionsDocument,
    options
  )
}
export function useLoadCovidRestrictionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadCovidRestrictionsQuery,
        LoadCovidRestrictionsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LoadCovidRestrictionsQuery, LoadCovidRestrictionsQueryVariables>(
    LoadCovidRestrictionsDocument,
    options
  )
}
export type LoadCovidRestrictionsQueryHookResult = ReturnType<typeof useLoadCovidRestrictionsQuery>
export type LoadCovidRestrictionsLazyQueryHookResult = ReturnType<
  typeof useLoadCovidRestrictionsLazyQuery
>
export type LoadCovidRestrictionsSuspenseQueryHookResult = ReturnType<
  typeof useLoadCovidRestrictionsSuspenseQuery
>
export type LoadCovidRestrictionsQueryResult = Apollo.QueryResult<
  LoadCovidRestrictionsQuery,
  LoadCovidRestrictionsQueryVariables
>
export const LoadVisasForCountryDocument = gql`
  query LoadVisasForCountry($countryNationalityIso: String!, $carriersNationalitiesIso: [String]!) {
    visasForCountry(
      countryNationalityIso: $countryNationalityIso
      carriersNationalitiesIso: $carriersNationalitiesIso
    ) {
      id
      name
      type
      carrierNationalityIso
      countryNationalityIso
      required
      feeAmount
      feeCurrency
    }
  }
`

/**
 * __useLoadVisasForCountryQuery__
 *
 * To run a query within a React component, call `useLoadVisasForCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadVisasForCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadVisasForCountryQuery({
 *   variables: {
 *      countryNationalityIso: // value for 'countryNationalityIso'
 *      carriersNationalitiesIso: // value for 'carriersNationalitiesIso'
 *   },
 * });
 */
export function useLoadVisasForCountryQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadVisasForCountryQuery,
    LoadVisasForCountryQueryVariables
  > &
    ({ variables: LoadVisasForCountryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoadVisasForCountryQuery, LoadVisasForCountryQueryVariables>(
    LoadVisasForCountryDocument,
    options
  )
}
export function useLoadVisasForCountryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadVisasForCountryQuery,
    LoadVisasForCountryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LoadVisasForCountryQuery, LoadVisasForCountryQueryVariables>(
    LoadVisasForCountryDocument,
    options
  )
}
export function useLoadVisasForCountrySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<LoadVisasForCountryQuery, LoadVisasForCountryQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LoadVisasForCountryQuery, LoadVisasForCountryQueryVariables>(
    LoadVisasForCountryDocument,
    options
  )
}
export type LoadVisasForCountryQueryHookResult = ReturnType<typeof useLoadVisasForCountryQuery>
export type LoadVisasForCountryLazyQueryHookResult = ReturnType<
  typeof useLoadVisasForCountryLazyQuery
>
export type LoadVisasForCountrySuspenseQueryHookResult = ReturnType<
  typeof useLoadVisasForCountrySuspenseQuery
>
export type LoadVisasForCountryQueryResult = Apollo.QueryResult<
  LoadVisasForCountryQuery,
  LoadVisasForCountryQueryVariables
>
