import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import { Fragment, useCallback, useContext } from 'react'
import ClearIcon from '~components/ui/Icon/ClearIcon'
import { NotificationsContext } from '~context/NotificationsContext'
import NotificationsList from './NotificationsList'
import NotificationsPanelHeader from './NotificationsPanelHeader'
import NotificationsTabHeader from './NotificationsTabHeader'

interface NotificationsPanelProps {
  defaultBucketId?: string
}

export default function NotificationsPanel({ defaultBucketId }: NotificationsPanelProps) {
  const {
    notificationPanelOpen,
    setNotificationPanelOpen,
    currentTab,
    setCurrentTab,
    setSelectedBucketId,
  } = useContext(NotificationsContext)
  const closePanel = useCallback(() => {
    if (defaultBucketId) {
      setSelectedBucketId(defaultBucketId)
    }
    setNotificationPanelOpen(false)
  }, [defaultBucketId, setNotificationPanelOpen, setSelectedBucketId])

  return (
    <Transition show={notificationPanelOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closePanel}>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="pointer-events-auto relative w-screen max-w-md">
                  <TransitionChild
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-600 hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={closePanel}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <ClearIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </TransitionChild>
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <NotificationsPanelHeader />
                    <div className="relative mt-6 flex-1">
                      <NotificationsTabHeader
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                      />
                      <NotificationsList />
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
